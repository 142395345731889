import { Alert, Button, Card, Col, Collapse, Divider, Drawer, Form, Input, message, Row, Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';
import {
  useDeleteIngredient,
  useGetIngredient,
  useGetIngredients,
  useGetIngredientsCategory,
  useGetProductsFromIngredients,
  useUpdateIngredients,
} from '../../../api/ingredients';
import { ProductIngredient } from '../../../model/ingredients';
import { ID } from '../../../utils/type';
import CreateIngredientInformation from '../AddIngredientInformation/CreateInformationPage';
import { InformationList } from '../InformationList/InformationList';
import SubIngredientsTree from '../SubIngredientsTree';
import IngredientNutrientsBlock from './IngredientNutrientsBlock';
import UpdateIngredientStyles from './UpdateIngredient.module.scss';

export interface UpdateIngredientProps {
  ingredientId: ID;
  onClose: (refetch?: boolean) => void;
}
const UpdateIngredient: FC<UpdateIngredientProps> = (props) => {
  const { ingredientId, onClose } = props;
  const history = useHistory();
  const { data, loading, error } = useGetIngredient({ variables: { id: ingredientId } });
  const ingredient = data?.ingredient;
  const [showIngredientInformation, setShowIngredientInformation] = useQueryParam<boolean>(
    'newInfo',
    withDefault(BooleanParam, false)
  );
  const [update] = useUpdateIngredients();
  const [_delete] = useDeleteIngredient();

  const onSave = (form: ProductIngredient) => {
    update({
      variables: {
        id: ingredientId,
        updateValues: {
          ...form,
          parentIngredient: form.parentIngredient?.id,
          categoryId: form.ingredientCategory?.id,
          ingredientCategory: undefined,
        } as any,
      },
    })
      .then((res) => {
        message.success('Updated ingredient ' + res.data?.newIngredient.name);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const [parentSearch, setParentSearch] = useState<string>();
  const [groupSearch, setGroupSearch] = useState<string>();
  const { data: parentResult, loading: parentLoading } = useGetIngredients({
    variables: {
      q: (parentSearch && parentSearch.length > 0 ? parentSearch : undefined)!, // FIXME: undefined
      offset: 0,
      limit: 30,
    },
    skip: parentSearch === undefined,
  });

  const { data: groupResult, loading: groupsLoading } = useGetIngredientsCategory({
    variables: {
      q: (groupSearch && groupSearch.length > 0 ? groupSearch : undefined)!, // FIXME: undefined
      offset: 0,
      limit: 30,
      childrenOnly: true,
    },
    skip: groupSearch === undefined,
  });

  const { data: ingredientsProducts } = useGetProductsFromIngredients({
    variables: { ingredientId: ingredientId },
  });

  useEffect(() => {
    if (ingredient && ingredient.parentIngredient?.name && parentSearch === undefined) {
      setParentSearch(ingredient.parentIngredient.name);
    }
  }, [ingredient, parentSearch]);

  useEffect(() => {
    if (ingredient && ingredient.ingredientCategory?.name && groupSearch === undefined) {
      setGroupSearch(ingredient.ingredientCategory?.name);
    }
  }, [ingredient, groupSearch]);

  const yesNoMaybeSelector = (
    <Select>
      <Select.Option value="yes">Yes</Select.Option>
      <Select.Option value="no">No</Select.Option>
      <Select.Option value="maybe">Maybe</Select.Option>
    </Select>
  );

  return (
    <>
      <Drawer
        width="40vw"
        title={<>Viewing {ingredient ? ingredient.name : <Spin />}</>}
        placement="right"
        onClose={() => {
          setGroupSearch(undefined);
          setParentSearch(undefined);
          onClose();
        }}
        visible={true}>
        <Card loading={loading}>
          {error && <Alert message={error.message} type={'error'} />}
          {!!ingredient && (
            <>
              <Row>
                <Col span="24">
                  <Button onClick={() => history.push(`/ingredients/${ingredient.id}/products`)} type="dashed" block>
                    See all {ingredientsProducts?.products.totalProducts} products with ingredient
                  </Button>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span="24">
                  <Collapse accordion>
                    <Collapse.Panel key={'all'} header={'See sub ingredients'}>
                      <SubIngredientsTree ingredient={ingredient} />
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>
              <Divider />
              <Form
                initialValues={ingredient}
                onFinish={onSave}
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                layout="vertical"
                disabled
                className={UpdateIngredientStyles.readonlyForm}>
                <Row gutter={[20, 0]}>
                  <Col xs={24} md={12}>
                    <Form.Item label="Name" name="name">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="Scientific Name" name="scientificName">
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 0]}>
                  <Col xs={24} md={12}>
                    <Form.Item label="Parent ingredient" name={['parentIngredient', 'id']}>
                      <Select
                        loading={parentLoading}
                        onSearch={debounce((e) => {
                          setParentSearch(e);
                        }, 500)}
                        value={parentSearch}
                        placeholder="Enter a parent ingredient"
                        allowClear={true}
                        filterOption={false}
                        showSearch>
                        {parentResult?.ingredients.data.map((v) => {
                          return (
                            <Select.Option key={v.id} value={v?.id}>
                              {v?.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item label="Ingredient category" name={['ingredientCategory', 'id']}>
                      <Select
                        loading={groupsLoading}
                        onSearch={debounce((e) => {
                          setGroupSearch(e);
                        }, 500)}
                        value={groupSearch}
                        placeholder="Enter a ingredient category"
                        allowClear={true}
                        filterOption={false}
                        showSearch>
                        {groupResult?.category.data.map((v) => {
                          return (
                            <Select.Option key={v.id} value={v.id}>
                              {v.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 0]}>
                  <Col xs={24} md={12}>
                    <Form.Item label="Synonyms" name="synonyms">
                      <Select
                        mode="tags"
                        placeholder="Enter or paste in a comma separated gtin list"
                        tokenSeparators={[',']}
                        maxTagCount={5}
                        allowClear={true}
                      />
                    </Form.Item>
                    <Form.Item label="Grocery number" name="groceryNumber">
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="E-number" name="eNumber">
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row align="bottom" gutter={[10, 0]}>
                  <Col xs={24} md={12} lg={8}>
                    <Form.Item label="Is vegan?" name="isVegan">
                      {yesNoMaybeSelector}
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12} lg={8}>
                    <Form.Item name="isVegetarian" label="Is vegetarian?">
                      {yesNoMaybeSelector}
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12} lg={8}>
                    <Form.Item name="isLactoVegetarian" label="Is lacto-vegetarian?">
                      {yesNoMaybeSelector}
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12} lg={8}>
                    <Form.Item name="isPescetarian" label="Is pescetarian?">
                      {yesNoMaybeSelector}
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12} lg={8}>
                    <Form.Item name="isOvoVegetarian" label="Is ovo-vegetarian?">
                      {yesNoMaybeSelector}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <Divider />

              <div style={{ marginBottom: '10px' }}>
                <InformationList ingredientInfromation={ingredient.ingredientInformation} />
              </div>
            </>
          )}
        </Card>
        {ingredient?.nutrients && (
          <IngredientNutrientsBlock nutrients={ingredient.nutrients} ingredientId={ingredient.id} />
        )}
      </Drawer>
      <Drawer
        onClose={() => setShowIngredientInformation(false)}
        placement="top"
        height="100vh"
        visible={showIngredientInformation}>
        {loading && <Spin />}
        {!!ingredient && (
          <CreateIngredientInformation
            ingredientId={ingredient.id}
            onComplete={() => {
              setShowIngredientInformation(false);
            }}
          />
        )}
      </Drawer>
    </>
  );
};

export default UpdateIngredient;
