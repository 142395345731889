import React, { FC, useContext, useMemo } from 'react';
import { Form } from 'antd';
import { EditRowForm } from '../context';

export interface ITableComponentProps {}

const TableComponent: FC = (props) => {
  const { children, ...restProps } = props;
  const form = useContext(EditRowForm);
  const Wrapper = useMemo(
    (): FC =>
      form
        ? (wrapperProps) => <Form form={form}>{wrapperProps.children}</Form>
        : (wrapperProps) => <>{wrapperProps.children}</>,
    [form]
  );
  return (
    <Wrapper>
      <table {...restProps}>{children}</table>
    </Wrapper>
  );
};

export default TableComponent;
