import { Row, Typography } from 'antd';
import { SignUp } from 'aws-amplify-react/lib/Auth';
import Authenticator from 'aws-amplify-react/lib/Auth/Authenticator';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const SignInPage = () => {
  const history = useHistory();
  const location = useLocation();

  const signInChange: any = async function (state: string, cognitoState: any) {
    if (state === 'verifyContact') {
      history.replace(new URLSearchParams(location.search).get('from') || '/');
    }
  };

  return (
    <>
      <Row className="header" justify="center" style={{ height: '40px', marginTop: '-60px' }}>
        <Link to="/">
          <Typography.Title level={4}>
            <img
              src={process.env.PUBLIC_URL + '/F_icon_square_leaf.png'}
              style={{ maxHeight: '40px', borderRadius: '100%', marginRight: '10px' }}
              alt="Foodfacts logo"
            />
            Foodfacts
          </Typography.Title>
        </Link>
      </Row>
      <Authenticator hide={[SignUp]} onStateChange={signInChange} />
    </>
  );
};

export default SignInPage;
