import { gql } from '@apollo/client';
import { ID } from 'src/utils/type';
import { IdentifierGroup } from '../model/group';
import { q, m } from '../utils/utils';
import { productBody } from './product';

type InputGroup = Omit<IdentifierGroup, 'products'> & { externalIds: string[] };

const GroupFragment = gql`
  ${productBody}
  fragment Group on IdentifierGroup {
    name
    description
    id
    totalProducts

    products(limit: $productLimit, offset: $productOffset) @include(if: $products) {
      products {
        ...ProductBody
      }
      total
    }
  }

  fragment GroupSearchResponse on IdentifierGroup {
    ...Group
    children(limit: 100000) @include(if: $children) {
      total
      data {
        ...Group
        children(limit: 100000) @include(if: $children) {
          total
          data {
            ...Group
            children(limit: 100000) @include(if: $children) {
              total
              data {
                ...Group
                children(limit: 100000) @include(if: $children) {
                  total
                  data {
                    ...Group
                    children(limit: 100000) @include(if: $children) {
                      total
                      data {
                        ...Group
                        children(limit: 100000) @include(if: $children) {
                          total
                          data {
                            ...Group
                            children(limit: 100000) @include(if: $children) {
                              total
                              data {
                                ...Group
                                children(limit: 100000) @include(if: $children) {
                                  total
                                  data {
                                    ...Group
                                    children(limit: 100000) @include(if: $children) {
                                      total
                                      data {
                                        ...Group
                                        children(limit: 100000) @include(if: $children) {
                                          total
                                          data {
                                            ...Group
                                            children(limit: 100000) @include(if: $children) {
                                              total
                                              data {
                                                ...Group
                                                children(limit: 100000) @include(if: $children) {
                                                  total
                                                  data {
                                                    ...Group
                                                    children(limit: 100000) @include(if: $children) {
                                                      total
                                                      data {
                                                        ...Group
                                                        children(limit: 100000) @include(if: $children) {
                                                          total
                                                          data {
                                                            ...Group
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
// Queries
/** */
const useGroupSearchSchema = gql`
  ${GroupFragment}
  query (
    $startsWith: String
    $rootGroupsOnly: Boolean
    $offset: Int
    $limit: Int
    $children: Boolean = true
    $products: Boolean = false
    $productLimit: Int = 10
    $productOffset: Int = 0
    $lite: Boolean = true
  ) {
    groups: searchGroup(startsWith: $startsWith, rootGroupsOnly: $rootGroupsOnly, offset: $offset, limit: $limit) {
      ...GroupSearchResponse
    }
  }
`;
type UseGroupSearchOuput = { groups: IdentifierGroup[] };
type UseGroupSearchInput = {
  limit?: number;
  offset?: number;
  startsWith?: string;
  rootGroupsOnly?: boolean;
  children?: boolean;
  products?: boolean;
  productLimit?: number;
  productOffset?: number;
};
export const useGroupSearch = q<UseGroupSearchOuput, UseGroupSearchInput>(useGroupSearchSchema);

/** */
export const useSingleGroupSchema = gql`
  ${GroupFragment}
  query useSingleGroupSchema(
    $id: ID!
    $productOffset: Int
    $productLimit: Int
    $children: Boolean = true
    $products: Boolean = true
    $lite: Boolean = true
  ) {
    group: getGroup(id: $id) {
      ...GroupSearchResponse
    }
  }
`;
export type UseSingleGroupOutput = { group: IdentifierGroup };
export type UseSingleGroupInput = {
  children?: boolean;
  products?: boolean;
  productLimit?: number;
  productOffset?: number;
  id: number;
};
export const useSingleGroup = q<UseSingleGroupOutput, UseSingleGroupInput>(useSingleGroupSchema);

// Mutations
/** */
const useUpdateGroupSchema = gql`
  mutation ($id: ID!, $name: String, $description: String, $externalIds: [String!]) {
    updatedGroup: updateGroup(id: $id, name: $name, description: $description, externalIds: $externalIds) {
      id
      name
      description
      totalProducts
      products {
        total
      }
    }
  }
`;
type UseUpdateGroupOutput = { updatedGroup: IdentifierGroup };
// todo in the future to not allow null values since this makes
// it so that we are not able to remove a description
type UseUpdateGroupInput = Partial<InputGroup>;
export const useUpdateGroup = m<UseUpdateGroupOutput, UseUpdateGroupInput>(useUpdateGroupSchema);

/** */
const useDeleteGroupSchema = gql`
  mutation deleteGroup($id: ID!) {
    deleted: deleteGroup(id: $id)
  }
`;
type UseDeleteGroupOutput = { deleted: boolean };
type UseDeleteGroupInput = { id: number };
export const useDeleteGroup = m<UseDeleteGroupOutput, UseDeleteGroupInput>(useDeleteGroupSchema, {
  update(cache, _, config) {
    try {
      const res = cache.readQuery<UseGroupSearchOuput>({
        query: useGroupSearchSchema,
      });

      if (res !== null) {
        if (res.groups)
          cache.writeQuery({
            query: useGroupSearchSchema,
            data: { groups: res.groups.filter((v) => config.variables?.id) },
          });
      }
    } catch {}
  },
});

/** */
type UseCreateGroupOutput = { createdGroup: IdentifierGroup };
type UseCreateGroupInput = InputGroup;
const useCreateGroupSchema = gql`
  mutation createGroup($name: String!, $description: String, $externalIds: [String!]) {
    createdGroup: createGroup(name: $name, description: $description, externalIds: $externalIds) {
      id
      name
      description
    }
  }
`;
export const useCreateGroup = m<UseCreateGroupOutput, UseCreateGroupInput>(useCreateGroupSchema, {
  update(cache, result) {
    try {
      const res = cache.readQuery<UseGroupSearchOuput>({ query: useGroupSearchSchema });
      const updatedRes: UseGroupSearchOuput = {
        groups: [...res!.groups, result.data!.createdGroup],
      }; /* FIXME: undefined! */
      if (res!.groups /* FIXME: undefined! */)
        cache.writeQuery<any, UseGroupSearchOuput>({ query: useGroupSearchSchema, data: updatedRes });
    } catch {}
  },
});

/** */
type UseDeleteFromGroupOutput = { createdGroup: IdentifierGroup };
type UseDeleteFromGroupInput = { id: number; externalIds: string[] };
const useDeleteFromGroupSchema = gql`
  mutation ($id: ID!, $externalIds: [String!]!) {
    newGroup: deleteFromGroup(id: $id, externalIds: $externalIds) {
      id
      totalProducts
      products {
        total
      }
    }
  }
`;
export const useDeleteFromGroup = m<UseDeleteFromGroupOutput, UseDeleteFromGroupInput>(useDeleteFromGroupSchema, {
  update(cache, _, config) {
    const groupId = config.variables?.id;
    const deleted = config.variables?.externalIds;

    if (!groupId) {
      throw new Error('Not able to update cache when the groupId is not found');
    }

    if (!deleted) {
      throw new Error('Not able to update cache when no deleted is found');
    }

    cache.modify({
      id: 'IdentifierGroup:' + groupId,
      fields: {
        // TODO find a way to make this typesafe
        products(products: any, details) {
          if (products.products) {
            console.log(products.products);
            return {
              ...products,
              products: products.products.filter((v: any) => {
                const gtin = details.readField('gtin', v) as string;
                console.log(gtin);
                return !deleted.includes(gtin);
              }),
            };
          } else {
            return products;
          }
        },
      },
    });
  },
});

/** */
type UseAddToGroupOutput = { group: IdentifierGroup };
type UseAddToFromGroupInput = { id: number; externalIds: string[] };
const useAddToGroupSchema = gql`
  mutation ($id: ID!, $externalIds: [String!]!) {
    group: addToGroup(id: $id, externalIds: $externalIds) {
      id
    }
  }
`;
export const useAddToGroup = m<UseAddToGroupOutput, UseAddToFromGroupInput>(useAddToGroupSchema, {
  update(cache, _, config) {
    const groupId = config.variables?.id;
    cache.evict({
      id: 'IdentifierGroup:' + groupId,
    });
  },
});

type UseAddChildGroupOutput = { group: IdentifierGroup };
type UseAddChildGroupInput = { childId: ID; parentId: ID };
const useAddChildGroupSchema = gql`
  mutation add($childId: ID!, $parentId: ID!) {
    group: addChildGroup(childId: $childId, parentId: $parentId) {
      id
      name
      totalProducts
      children(limit: 100000) {
        data {
          totalProducts
          id
          name
        }
        total
      }
    }
  }
`;
export const useAddChildGroup = m<UseAddChildGroupOutput, UseAddChildGroupInput>(useAddChildGroupSchema, {
  update(cache, res) {},
});

/** */
type UseRemoveChildGroupOutput = UseAddChildGroupOutput;
type UseRemoveChildGroupInput = { childId: ID; parentId: ID };
const useRemoveChildGroupSchema = gql`
  mutation add($childId: ID!, $parentId: ID!) {
    group: removeChildGroup(childId: $childId, parentId: $parentId) {
      id
      name
      totalProducts
      children(limit: 100000) {
        data {
          totalProducts
          id
          name
        }
        total
      }
    }
  }
`;
export const useRemoveChildGroup = m<UseRemoveChildGroupOutput, UseRemoveChildGroupInput>(useRemoveChildGroupSchema);
