import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { gql, useLazyQuery } from '@apollo/client';
import { Empty } from 'antd';
import { useEffect } from 'react';
import { Product } from 'src/model/product';
import { IconText } from '../IconText';
import { ProductList } from '../ProductList';

const Query = gql`
  query {
    lastViewdProducts(limit: 20, offset: 0) {
      id
      name
      brandName
      productTags {
        name
      }
      productImage(size: large) {
        url
      }
      productIdentifier {
        externalId
        targetMarket {
          name
        }
      }
      interpretedIngredients {
        id
        message
        approval {
          ok
        }
      }
    }
  }
`;

export function HistorySearch() {
  const [reqHistory, { data: response, refetch }] = useLazyQuery<{
    lastViewdProducts: Product[];
  }>(Query, {
    variables: { limit: 20, offset: 0 },
  });

  useEffect(() => {
    refetch();
  }, []);

  if (response) {
    if (response.lastViewdProducts.length) {
      return (
        <ProductList
          actions={(product) => {
            const ApprovalIcon = () => {
              if (product.interpretedIngredients?.approval?.ok === true)
                return <CheckCircleFilled style={{ color: 'green' }} />;
              if (product.interpretedIngredients?.approval?.ok === false)
                return <CloseCircleFilled style={{ color: 'red' }} />;

              return <CloseCircleFilled style={{ color: 'grey' }} />;
            };
            return [<IconText icon={ApprovalIcon} text="Product is approved" key="list-vertical-star-o" />];
          }}
          hasMore={false}
          products={response.lastViewdProducts}
        />
      );
    } else {
      return <Empty description="No history" />;
    }
  } else {
    return <LoadingOutlined />;
  }
}
