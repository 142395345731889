import { Button, Drawer, Form, Input, message, Select } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SAVE_ALLERGEN_TAG } from '../../api/tag';
import { useMutation } from '@apollo/client';
import { Tag } from '../../model/tag';
import { useTypeCode } from '../../api/typeCode';
import { TypeCode } from '../../model/product';

const { Option } = Select;

export const typeCodeOptions = (typeCodes?: TypeCode[]) => {
  return typeCodes?.map((typeCode) => {
    return (
      <Option key={typeCode.code} value={typeCode.code} label={typeCode.name}>
        {typeCode.name}
      </Option>
    );
  });
};

const AddTagPage = () => {
  const onClose = () => {};
  const history = useHistory();
  const [saveTag] = useMutation<{ saveAllergenTag: Tag }>(SAVE_ALLERGEN_TAG);
  const { data: typeCodeData } = useTypeCode({ variables: { code: 'T4078' } });
  const [form] = Form.useForm();

  const onFormSubmit = (tag: unknown) => {
    saveTag({
      variables: { tag: tag },
      refetchQueries: ['getAllergenTags'],
    })
      .then(() => {
        message.success('Tag saved successfully');
        history.goBack();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onFormReset = () => {
    form.resetFields(['tag', 'name', 'description']);
    history.goBack();
  };

  return (
    <Drawer title="Add new allergen tag" placement={'right'} closable={false} onClose={onClose} visible={true}>
      <Form form={form} name="tag-add-form" onFinish={onFormSubmit} layout={'vertical'}>
        <Form.Item name="tag" label="Tag" rules={[{ required: true, message: 'Tag is required' }]}>
          <Input placeholder="Tag" />
        </Form.Item>

        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Tag name is required' }]}>
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input placeholder="Description" />
        </Form.Item>

        <Form.Item name={['gs1Code', 'code']} label="Matching GS1 Code">
          <Select
            showSearch
            placeholder="Select an allergen code"
            filterOption={(input, option) => {
              // @ts-ignore
              return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}>
            {typeCodeOptions(typeCodeData?.typeCodes)}
          </Select>
        </Form.Item>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onFormReset} style={{ marginRight: '10px' }}>
            Close
          </Button>
          <Button htmlType={'submit'} type={'primary'}>
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default AddTagPage;
