import { Button, Col, Popconfirm, Row, Table, Typography } from 'antd';
import { Breadcrumb } from '../../components/Breadcrumb';

import { DeleteOutlined } from '@ant-design/icons/lib';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';
import { ColumnsType } from 'antd/lib/table/interface';
import produce from 'immer';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'src/components/AuthProvider';
import { HelpModal } from 'src/components/HelpModal';
import { NoPermissionBoundary } from 'src/components/NoPermission/NoPermissionBoundary';
import { withNoPermissionPage } from 'src/components/NoPermission/NoPermissionPage';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useDeleteGroup, useGroupSearch } from '../../api/group';
import { IdentifierGroup } from '../../model/group';
import { ROUTER_PAGES } from '../../routes';

export const groupListPageSize = 10;

function GroupListPage() {
  const { data: groups, refetch, loading } = useGroupSearch();
  const history = useHistory();
  const user = useContext(AuthContext);

  const [page, setPage] = useQueryParam<number>('page', withDefault(NumberParam, 1));
  const [deleteGroup] = useDeleteGroup();

  const onDeleteGroup = (groupId: number): void => {
    deleteGroup({
      variables: {
        id: groupId,
      },
    })
      .then(() => {
        return refetch();
      })
      .catch((v) => {
        console.error(v);
      });
  };

  const columns: ColumnsType<IdentifierGroup> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',

      render(_, item) {
        return <b>{item.name}</b>;
      },
    },
    {
      title: 'Description',
      key: 'description',
      render(_, item) {
        return item.description || '-';
      },
      responsive: ['lg'] as Breakpoint[],
    },
    {
      title: '',
      key: 'actions',
      render: (actions, group) => {
        return (
          <Row gutter={[20, 0]} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Col>
              <NoPermissionBoundary identifyPermission={(permissions) => permissions.productService.write}>
                <Popconfirm
                  placement="left"
                  title="Are you sure to delete this group?"
                  onConfirm={(event) => {
                    event?.stopPropagation();
                    onDeleteGroup(group.id);
                  }}
                  onCancel={(event) => {
                    event?.stopPropagation();
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    icon={<DeleteOutlined />}
                    shape="circle"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                </Popconfirm>
              </NoPermissionBoundary>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <div>
      <Breadcrumb />
      <Typography.Title level={1}>
        <Row justify="space-between" align="middle">
          Groups
          <HelpModal
            title="Group page"
            description="From the groups page you can create groups that contain specific products to more easily be able to sort products in the system. To create a group, press the &#34;add new&#34; button and select a name and description for your group, then simply add as many GTINS of products as you want for the group. A tip is to upload the GTINS to a csv first and use to &#34;Upload CSV file&#34; feature to more easily be able to add a lot of products to a group. If you press the &#34;Edit hierarchy&#34; button on the groups page, you can add a hierarchy to your groups. For example, you might have a &#34;dairy&#34; group containing the subgroups &#34;milk&#34; and &#34;cheese&#34;."
          />
        </Row>
      </Typography.Title>
      <Table
        loading={loading}
        size={'middle'}
        dataSource={produce(groups?.groups, (groups) => {
          if (!groups) return undefined;

          groups.forEach((item) => {
            delete item.children;
          });

          return groups;
        })}
        columns={columns}
        rowKey={'id'}
        title={() => (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <NoPermissionBoundary identifyPermission={(permissions) => permissions.productService.write}>
              <Button type={'primary'} onClick={() => history.push(ROUTER_PAGES.addNewGroup.path)}>
                Add new
              </Button>
            </NoPermissionBoundary>
          </div>
        )}
        pagination={{
          current: page,
          pageSize: groupListPageSize,
          position: ['bottomCenter'],
          onChange: (page) => setPage(page, 'replace'),
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              if (user?.userGroup.permissions.productService.write) {
                history.push(ROUTER_PAGES.editGroups.path.replace(':id', String(record?.id)));
              }
            },
          };
        }}
      />
    </div>
  );
}

export default withNoPermissionPage(GroupListPage, (permissions) => permissions.productService.read);
