import { FC, useContext, useMemo, useState } from 'react';
import { InterpretedIngredient } from '../../../../model/ingredients';
import { IsEditingContext } from '../context';
import { Alert, Form, Select, Space, Tooltip } from 'antd';
import { notNullGuard } from '../../../../utils/typescript';
import { SyncOutlined } from '@ant-design/icons';
import { useGetIngredients } from 'src/api/ingredients';

export interface IIngredientCellProps {
  record: InterpretedIngredient;
}

interface IOptionType {
  label: string;
  value: string;
}

export const IngredientCell: FC<IIngredientCellProps> = (props) => {
  const { record } = props;
  const isEditing = useContext(IsEditingContext);
  const [query, setQuery] = useState<string>();
  const { data, loading, error, refetch } = useGetIngredients({ variables: { q: query } });

  const options = useMemo((): IOptionType[] | undefined => {
    return data?.ingredients.data
      .map((ingredient) => {
        return [
          {
            label: ingredient.name,
            value: ingredient.id.toString(),
          },
        ];
      })
      .flat();
  }, [data?.ingredients.data]);

  if (!isEditing) {
    return <>{record.ingredient?.name || '-'}</>;
  }

  if (error) {
    return <Alert type={'error'} message={String(error)} />;
  }
  return (
    <Form.Item style={{ marginBottom: 0 }}>
      <Space>
        <Form.Item name={'ingredient'} noStyle={true}>
          <Select
            showSearch={true}
            options={options}
            filterOption={(search, option) => {
              return true;
            }}
            allowClear={true}
            showArrow={false}
            onSearch={(ev) => {
              setQuery(ev);
            }}
            style={{ minWidth: '200px' }}
          />
        </Form.Item>
        <Tooltip title="click to refresh ingredients list">
          <SyncOutlined spin={loading} onClick={() => refetch()} />
        </Tooltip>
      </Space>
    </Form.Item>
  );
};
