import { gql, QueryResult, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { ID } from '../../../utils/type';
import { notNullGuard } from '../../../utils/typescript';

const getIngredientRelationshipsSchema = gql`
  query getIngredientRelationships {
    getIngredients(offset: 0, limit: 99999999, q: "") {
      data {
        id
        name
        parentIngredient {
          id
        }
      }
      totalAmount
    }
  }
`;

interface IngredientRef {
  id: ID;
}
type ResponseIngredient = {
  id: ID;
  name: string;
  parentIngredient: null | {
    id: ID;
  };
};
type Response = {
  getIngredients: null | {
    data: Array<null | ResponseIngredient>;
  };
};
type ArrayLikeData = (IngredientRef & {
  name: string;
  parentIngredient: IngredientRef | null;
})[];
type MapEntry = IngredientRef & {
  name: string;
  children: (IngredientRef & { name: string })[];
};
type ReadonlyMapEntry = IngredientRef & {
  name: string;
  children: readonly (IngredientRef & { name: string })[];
};
type ReadonlyIngredientsMap = ReadonlyMap<string, ReadonlyMapEntry>;

function toMap(source: ArrayLikeData): Map<string, MapEntry> {
  const ingredientsMap = new Map<string, MapEntry>(
    source.map((ingredient) => [
      ingredient.id.toString(),
      {
        id: ingredient.id,
        name: ingredient.name,
        children: [],
      },
    ])
  );
  for (const ingredient of source) {
    const ingredientTreeNode = ingredientsMap.get(ingredient.id.toString());
    if (ingredientTreeNode) {
      const { parentIngredient } = ingredient;
      if (parentIngredient) {
        const parentTreeNode = ingredientsMap.get(parentIngredient.id.toString());
        if (parentTreeNode) {
          if (!parentTreeNode.children.includes(ingredientTreeNode)) {
            parentTreeNode.children.push(ingredientTreeNode);
          }
        }
      }
    }
  }
  return ingredientsMap;
}
export function useIngredientRelationshipsMap(): [QueryResult<Response>, ReadonlyIngredientsMap] {
  // FIXME: //  `api/client`, has a field policy for the request and it is not good enough, mess the data and honestly I do not know if something is broken after the request
  const queryResult = useQuery<Response>(getIngredientRelationshipsSchema);
  const map = useMemo(() => {
    if (!queryResult.data) {
      return new Map();
    }
    const ingredientsData = queryResult.data.getIngredients;
    if (!ingredientsData) {
      return new Map();
    }
    const ingredients = ingredientsData.data.filter(notNullGuard);
    return toMap(ingredients);
  }, [queryResult.data]);

  return useMemo(() => [queryResult, map], [queryResult, map]);
}
