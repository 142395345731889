import AddNewGroupPage from './pages/GroupPage/AddNewGroupPage';
import EditGroupPage from './pages/GroupPage/EditGroupPage';
import FindProductPage from './pages/GroupPage/FindProductsPage';
import GroupHierarchy from './pages/GroupPage/GroupHierarchy';
import GroupListPage from './pages/GroupPage/GroupListPage';
import IngredientListPage from './pages/IngredientsPage/IngredientListPage';
import ProductIngredientSearch from './pages/IngredientsPage/ProductIngredientSearch/ProductIngredientSearch';
import PreviewProductPage from './pages/PreviewPage/PreviePage';
import EditProductPage from './pages/ProductPage/EditProductPage';
import ProductPage from './pages/ProductPage/ProductPage';
import ProductsComparePage from './pages/ProductPage/ProductsComparePage';
import QueryPage from './pages/QueryPage/QueryPage';
import SignInPage from './pages/SignInPage/SignInPage';
import AddTagPage from './pages/TagPages/AddNewAllergenTagPage';
import TagListPage from './pages/TagPages/TagListPage';

export const ROUTER_PAGES = {
  search: {
    path: '/',
    component: QueryPage,
    exact: true,
  },
  productsCompare: {
    path: '/products/compare/:gtin',
    component: ProductsComparePage,
    exact: true,
  },
  editProduct: {
    path: '/products/:id/edit',
    component: EditProductPage,
    skip: true,
  },
  products: {
    path: '/products/:id',
    component: ProductPage,
  },
  signIn: {
    path: '/signin',
    component: SignInPage,
  },
  tags: {
    path: '/tags',
    component: TagListPage,
  },
  addNewTag: {
    path: '/tags/new',
    component: AddTagPage,
    skip: true,
  },
  productPreviewPage: {
    path: '/public/products/:id',
    component: PreviewProductPage,
  },
  addNewGroup: {
    path: '/groups/new',
    component: AddNewGroupPage,
    exact: true,
  },
  editTag: {
    path: '/tags/:tag',
    component: null,
    skip: true,
  },
  groups: {
    path: '/groups',
    component: GroupListPage,
    exact: true,
  },
  editGroups: {
    path: '/groups/:id',
    component: EditGroupPage,
    exact: true,
  },
  groupHierachy: {
    path: '/groups-hierarchy',
    component: GroupHierarchy,
    exact: true,
  },
  findProduct: {
    path: '/groups/:id/find',
    component: FindProductPage,
    exact: true,
  },
  ingredientsEdit: {
    path: '/ingredients',
    component: IngredientListPage,
    exact: true,
  },
  productIngredientSearch: {
    path: '/ingredients/:id/products',
    component: ProductIngredientSearch,
    exact: true,
  },
};
