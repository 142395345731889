import { gql } from '@apollo/client';
import { TypeCode } from '../model/product';
import { q } from '../utils/utils';

const useAccreditedMarkingsSchema = gql`
  query {
    typeCodes: accreditationCodes {
      code
      name
      usageInSweden
    }
  }
`;
export const useAccreditedMarkings = q<{ typeCodes: TypeCode[] }>(useAccreditedMarkingsSchema);
