import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Button, Drawer, Space, Table, Tree } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { GetComponentProps, TableComponents } from 'rc-table/lib/interface';
import { FC, useCallback, useMemo, useState } from 'react';
import { InterpretedIngredient } from '../../../model/ingredients';
import { AmountCell } from './cells/AmountCell';
import { DescriptionsCell } from './cells/DescriptionsCell';
import { EcoCell } from './cells/EcoCell';
import { FoundNameCell } from './cells/FoundNameCell';
import { IngredientCell } from './cells/IngredientCell';
import { LifeStyleTags } from './cells/LifeStyleTags';
import { OriginCell } from './cells/OriginCell';
import { buildTreeData } from './data';
import { Ingredient } from './Ingredient';
import { Row } from './Row';
import styles from './styles.module.scss';
import TableComponent from './TableComponent';
import TableFooter from './TableFooter';
import { IExtraRowProps } from './types';

/// http://localhost:3000/products/180350
interface IProps {
  disabled: boolean;
  productId: number | string;
  ingredients: InterpretedIngredient[];
  originalIngredients: string;
  isExpandable?: boolean;
}

export const InterpretedIngredientList: FC<IProps> = (props: IProps) => {
  const { ingredients, isExpandable = true, productId, disabled, originalIngredients } = props;

  const [jaccardCutoff] = useState(Number.MIN_SAFE_INTEGER);

  const treeData = useMemo(
    () =>
      buildTreeData({
        items: ingredients,
        jaccardCutoff,
        ItemComponent: Ingredient,
        isEditable: !disabled,
      }),
    [disabled, ingredients, jaccardCutoff]
  );

  const [tableExpanded, setTableExpanded] = useState(false);
  const onExpandTable = useCallback((): void => {
    setTableExpanded(true);
  }, []);
  const onShrinkTable = useCallback((): void => {
    setTableExpanded(false);
  }, []);

  return (
    <>
      {isExpandable && (
        <div className={styles.controls}>
          {tableExpanded ? (
            <Button icon={<ShrinkOutlined />} onClick={onShrinkTable} />
          ) : (
            <Button icon={<ExpandAltOutlined />} onClick={onExpandTable} />
          )}
        </div>
      )}
      <Drawer
        visible={tableExpanded}
        footer={null}
        placement="top"
        onClose={onShrinkTable}
        title={'Interpreted Ingredient'}
        height={'100vh'}
        className={styles.modal}
        destroyOnClose={true}>
        <InterpretedIngredientsTable
          originalIngredients={originalIngredients}
          ingredients={ingredients}
          productId={productId}
        />
      </Drawer>
      <Tree defaultExpandAll treeData={treeData} blockNode disabled={disabled} />
    </>
  );
};

export interface IIngredientsTableProps {
  ingredients: InterpretedIngredient[];
  productId: number | string;
  originalIngredients: string;
}

export const InterpretedIngredientsTable: FC<IIngredientsTableProps> = (props) => {
  const { ingredients, productId, originalIngredients } = props;

  const dataSource = useMemo(() => ingredients, [ingredients]);

  const columns = useMemo((): ColumnsType<InterpretedIngredient> => {
    const columns: ColumnsType<InterpretedIngredient> = [
      {
        title: 'id',
        dataIndex: 'id',
      },
      {
        title: 'Found name',
        dataIndex: 'foundName',
        render: (value, record) => <FoundNameCell record={record} />,
      },
      {
        title: 'Ingredient',
        dataIndex: 'ingredient.id',
        width: 200,
        render: (value, record) => <IngredientCell record={record} />,
      },
      {
        title: 'Descriptions',
        dataIndex: 'descriptions',
        shouldCellUpdate(record, prevRecord) {
          return record.descriptions !== prevRecord.descriptions;
        },
        render: (value, record) => <DescriptionsCell record={record} />,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: 80,
        shouldCellUpdate(record, prevRecord) {
          return record.amount !== prevRecord.amount;
        },
        render: (value, record) => <AmountCell record={record} />,
      },
      {
        title: 'Eco',
        dataIndex: 'eco',
        width: 120,
        shouldCellUpdate(record, prevRecord) {
          return record.eco !== prevRecord.eco;
        },
        render: (value: InterpretedIngredient['eco'], record) => <EcoCell record={record} />,
      },
      {
        title: 'Lifetags',
        dataIndex: 'undefined',
        render: (value: unknown, record) => {
          const { ingredient } = record;
          if (!ingredient) {
            return null;
          } else {
            return (
              <LifeStyleTags
                isLactoVegetarian={ingredient.isLactoVegetarian}
                isOvoVegetarian={ingredient.isOvoVegetarian}
                isPescetarian={ingredient.isPescetarian}
                isVegan={ingredient.isVegan}
                isVegetarian={ingredient.isVegetarian}
              />
            );
          }
        },
      },
      {
        title: 'Origin',
        dataIndex: 'origin',
        width: 120,
        shouldCellUpdate(record, prevRecord) {
          return record.origin !== prevRecord.origin;
        },
        render: (value: InterpretedIngredient['origin'], record) => <OriginCell record={record} />,
      },
    ];

    return columns;
  }, []);

  const components = useMemo((): TableComponents<InterpretedIngredient> => {
    return {
      table: TableComponent,
      body: {
        row: Row,
      },
    };
  }, []);

  const onRow: GetComponentProps<InterpretedIngredient> = useCallback((record, index) => {
    const extraProps: IExtraRowProps = {
      index: index || 0,
      record,
    };
    return {
      ...({
        ...extraProps,
      } as any),
    };
  }, []);

  const renderFooter = useCallback(() => {
    return <TableFooter productId={productId} />;
  }, [productId]);

  return (
    <Space direction={'vertical'}>
      <div>{originalIngredients}</div>
      <Table
        expandable={{ defaultExpandAllRows: true }}
        className={styles.table}
        dataSource={dataSource}
        columns={columns}
        rowKey={'id'}
        components={components}
        onRow={onRow}
        footer={renderFooter}
        pagination={false}
      />
    </Space>
  );
};
