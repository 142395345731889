import { gql } from '@apollo/client';
import {
  IngredientCategory,
  IngredientDescriptor,
  IngredientStatistics,
  ProductIngredient,
} from '../model/ingredients';
import { Product } from '../model/product';
import { ID } from '../utils/type';
import { lazyQuery, m, q } from '../utils/utils';
import { ING_HIT_CHILDREN, LIFE_TAGS, productBody } from './product';

type QueryInput = { offset?: number; limit?: number; q?: string; asc?: boolean; sortField?: string | null };

export const ingredientNutrientBody = gql`
  fragment IngredientNutrientsBody on IngredientNutrient {
    id
    nutrientId
    nutrientAmount
    nutrientType
    nutrientUnit
    nutrientSource {
      id
      name
    }
  }
`;
export const ingredientBody = gql`
  fragment IngredientBody on Ingredient {
    id
    name
    isVegan
    isVegetarian
    isLactoVegetarian
    isOvoVegetarian
    isPescetarian
    scientificName
    synonyms
    eNumber
    lastChangedBy
    groceryNumber
    ingredientInformation {
      id
      type
      body
      isPublic
      source {
        id
        name
        website
        iconUrl
      }
    }
    nutrients {
      ...IngredientNutrientsBody
    }

    ingredientCategory {
      id
      name
      parentGroup {
        id
        name
      }
    }
    parentIngredient {
      lastChangedBy
      name
      id
      name
      isVegan
      isVegetarian
      isLactoVegetarian
      isOvoVegetarian
      isPescetarian
      scientificName
      synonyms
      eNumber
      groceryNumber
      ingredientCategory {
        id
        name
        parentGroup {
          id
          name
        }
      }
    }
  }

  ${ingredientNutrientBody}
`;
// Query
/** */
type UseGetIngredientsOutput = { ingredients: { data: ProductIngredient[]; totalAmount: number } };
type UseGetIngredientsInput = QueryInput;
const useGetIngredientsSchema = gql`
  ${ingredientBody}
  query ($offset: Int, $limit: Int, $q: String, $asc: Boolean, $sortField: IngredientSortField) {
    ingredients: getIngredients(offset: $offset, limit: $limit, q: $q, asc: $asc, sort: $sortField) {
      data {
        ...IngredientBody
      }
      totalAmount
    }
  }
`;
export const useGetIngredients = q<UseGetIngredientsOutput, UseGetIngredientsInput>(useGetIngredientsSchema);

type UseIngredientsByIdOutput = { ingredients: ProductIngredient[] };
type UseIngredientsByIdInput = { ingredientIds: ID[] };
const useIngredientsByIdSchema = gql`
  ${ingredientBody}
  query ($ingredientIds: [ID]) {
    ingredients: ingredientsById(ingredientIds: $ingredientIds) {
      ...IngredientBody
    }
  }
`;
export const useIngredientsById = q<UseIngredientsByIdOutput, UseIngredientsByIdInput>(useIngredientsByIdSchema);

type UseGetIngredientOutput = { ingredient: ProductIngredient };
type UseGetIngredientInput = { id: ID };
const useGetIngredientSchema = gql`
  ${ingredientBody}
  query getIngredient($id: ID!) {
    ingredient: getIngredient(id: $id) {
      ...IngredientBody
    }
  }
`;
export const useGetIngredient = q<UseGetIngredientOutput, UseGetIngredientInput>(useGetIngredientSchema);

// Mutation

type UseGetIngredientsCategoryInput = QueryInput & { childrenOnly?: boolean };
type UseGetIngredientsCategoryOutput = { category: { data: IngredientCategory[]; totalAmount: number } };
const useGetIngredientCategorySchema = gql`
  query ($offset: Int, $limit: Int, $q: String, $childrenOnly: Boolean) {
    category: getIngredientCategories(offset: $offset, limit: $limit, q: $q, childrenOnly: $childrenOnly) {
      data {
        id
        name
        parentGroup {
          id
          name
        }
      }
      totalAmount
    }
  }
`;
export const useGetIngredientsCategory = q<UseGetIngredientsCategoryOutput, UseGetIngredientsCategoryInput>(
  useGetIngredientCategorySchema
);

type UseUpdateIngredientsInput = { id: ID; updateValues: ProductIngredient };
type UseUpdateIngredientsOutput = { newIngredient: ProductIngredient };
const useUpdateIngredientsSchema = gql`
  mutation ($id: ID!, $updateValues: UpdateIngredientInput!) {
    newIngredient: updateProductIngredient(id: $id, updateValues: $updateValues) {
      id
      name
      isVegan
      isVegetarian
      isLactoVegetarian
      synonyms
      eNumber
      lastChangedBy
      ingredientCategory {
        id
        name
        parentGroup {
          id
          name
        }
      }
    }
  }
`;

type UseIngredientCategoriesInput = QueryInput & { childrenOnly?: boolean };
type UseIngredientCategoriesOutput = { category: { data: IngredientCategory[]; totalAmount: number } };
const useIngredientCategoriesSchema = gql`
  query ($offset: Int, $limit: Int, $q: String, $childrenOnly: Boolean) {
    category: ingredientCategories(offset: $offset, limit: $limit, q: $q, childrenOnly: $childrenOnly) {
      data {
        id
        name
        parentGroup {
          id
          name
        }
      }
      totalAmount
    }
  }
`;

export const useIngredientCategories = q<UseIngredientCategoriesOutput, UseIngredientCategoriesInput>(
  useIngredientCategoriesSchema
);

export const useUpdateIngredients = m<UseUpdateIngredientsOutput, UseUpdateIngredientsInput>(
  useUpdateIngredientsSchema
);

type UseCreateIngredientsInput = { values: ProductIngredient };
type UseCreateIngredientsOutput = { newIngredient: ProductIngredient };
const useCreateIngredientsSchema = gql`
  mutation ($values: UpdateIngredientInput!) {
    newIngredient: createProductIngredient(values: $values) {
      id
      name
      isVegan
      isVegetarian
      eNumber
      ingredientCategory {
        id
        name
        parentGroup {
          id
          name
        }
      }
    }
  }
`;
export const useCreateIngredients = m<UseCreateIngredientsOutput, UseCreateIngredientsInput>(
  useCreateIngredientsSchema
);

type UseInterpretProductIngredientsInput = { id: ID; ingredientStatement?: string };
type UseInterpretProductIngredientsOutput = { response: Product };

const useInterpretProductIngredientsSchema = gql`
  mutation ($id: ID!, $ingredientStatement: String) {
    repsonse: predictProductIngredientsOnProduct(id: $id, ingredientStatement: $ingredientStatement) {
      id
      interpretedAllergens {
        data {
          allergen
          allergenLevel
          reason
        }
      }
      interpretationIngredientStatement
      interpretedLifestyles {
        ...LifeTagFields
      }
      interpretedIngredients {
        id
        average
        message
        approval {
          ok
          id
          lastChangedBy
        }
        changes {
          changes
        }
        data {
          ...ingHit
          ...ingHitChildren
        }
      }
    }
  }

  ${ING_HIT_CHILDREN}

  ${LIFE_TAGS}
`;
export const useInterpretProductIngredients = m<
  UseInterpretProductIngredientsOutput,
  UseInterpretProductIngredientsInput
>(useInterpretProductIngredientsSchema);

type UseDeleteIngredientInput = { id: ID };
type UseDeleteIngredientOutput = { deleted: boolean };
const deleteIngredient = gql`
  mutation ($id: ID!) {
    deleted: deleteProductIngredient(id: $id)
  }
`;
export const useDeleteIngredient = m<UseDeleteIngredientOutput, UseDeleteIngredientInput>(deleteIngredient);

type UseIngredientsStatsOutput = { stats: IngredientStatistics };
type UseIngredientsStatsInput = { maxJaccard: number; offset?: number; limit: number };
const useIngredientsStatsSchema = gql`
  query ($maxJaccard: Float, $offset: Int, $limit: Int!) {
    stats: getPredictionStatistics {
      globalCount
      globalAverage
      globalParsedProductsCount
      missingIngredients(limit: $limit, maxJaccard: $maxJaccard, offset: $offset) {
        foundName
        jaccard
        productId
      }
    }
  }
`;
export const useIngredientStats = q<UseIngredientsStatsOutput, UseIngredientsStatsInput>(useIngredientsStatsSchema);

type UseGetIngredientsDescriptorsOutput = { descriptors: { data: IngredientDescriptor[]; totalAmount: number } };
const useGetIngredientsDescriptorsSchema = gql`
  query ($offset: Int, $limit: Int, $q: String) {
    descriptors: getIngredientDescriptors(offset: $offset, limit: $limit, q: $q) {
      data {
        id
        name
        isJoinable
        isPreparationMethod
        isColor
        isOrigin
        isShape
        isPiecePart
      }
      totalAmount
    }
  }
`;
export const useGetIngredientsDescriptors = q<UseGetIngredientsDescriptorsOutput, QueryInput>(
  useGetIngredientsDescriptorsSchema
);

type UseCreateIngredientsDescriptorOutput = { descriptor: IngredientDescriptor };
type UseCreateIngredientsDescriptorInput = { values: Partial<IngredientDescriptor> };
const useCreateIngredientsDescriptorSchema = gql`
  mutation ($values: UpdateIngredientDescriptorInput!) {
    descriptor: createIngredientDescriptor(values: $values) {
      id
      name
      isJoinable
      isPreparationMethod
      isColor
      isOrigin
      isShape
      isPiecePart
    }
  }
`;
export const useCreateIngredientsDescriptor = m<
  UseCreateIngredientsDescriptorOutput,
  UseCreateIngredientsDescriptorInput
>(useCreateIngredientsDescriptorSchema);

type UseUpdateIngredientsDescriptorOutput = { descriptor: IngredientDescriptor };
type UseUpdateIngredientsDescriptorInput = { values: Partial<IngredientDescriptor>; id: ID };
const useUpdateIngredientsDescriptorSchema = gql`
  mutation ($id: ID!, $values: UpdateIngredientDescriptorInput!) {
    descriptor: updateIngredientDescriptor(id: $id, values: $values) {
      id
      name
      isJoinable
      isPreparationMethod
      isColor
      isOrigin
      isShape
      isPiecePart
    }
  }
`;
export const useUpdateIngredientsDescriptor = m<
  UseUpdateIngredientsDescriptorOutput,
  UseUpdateIngredientsDescriptorInput
>(useUpdateIngredientsDescriptorSchema);

type UseDeleteIngredientDescriptorOutput = { ok: boolean };
type UseDeleteIngredientDescriptorInput = { id: ID };
const useDeleteIngredientDescriptorSchema = gql`
  mutation ($id: ID!) {
    ok: deleteIngredientDescriptor(id: $id)
  }
`;
export const useDeleteIngredientDescriptor = m<UseDeleteIngredientDescriptorOutput, UseDeleteIngredientDescriptorInput>(
  useDeleteIngredientDescriptorSchema
);

type UseCreateIngredientsGroupOutput = { category: IngredientCategory };
type UseCreateIngredientsGroupInput = { values: Omit<IngredientCategory, 'id'> };
const useCreateIngredientsGroupSchema = gql`
  mutation ($values: UpdateIngredientCategoryInput!) {
    category: createIngredientCategory(values: $values) {
      name
      id
      parentGroup {
        id
        name
      }
    }
  }
`;
export const useCreateIngredientsGroup = m<UseCreateIngredientsGroupOutput, UseCreateIngredientsGroupInput>(
  useCreateIngredientsGroupSchema
);

type UseUpdateIngredientsGroupOutput = { category: IngredientCategory };
type UseUpdateIngredientsGroupInput = { values: Omit<IngredientCategory, 'id'>; id: ID };
const useUpdateIngredientGroupSchema = gql`
  mutation ($values: UpdateIngredientCategoryInput!, $id: ID!) {
    category: updateIngredientCategory(id: $id, values: $values) {
      name
      id
      parentGroup {
        id
        name
      }
    }
  }
`;
export const useUpdateIngredientsGroup = m<UseUpdateIngredientsGroupOutput, UseUpdateIngredientsGroupInput>(
  useUpdateIngredientGroupSchema
);

type UseDeleteIngredientsGroupOutput = { ok: boolean };
type UseDeleteIngredientsGroupInput = { id: ID };
const deleteIngredientGroup = gql`
  mutation ($id: ID!) {
    ok: deleteIngredientCategory(id: $id)
  }
`;
export const useDeleteIngredientsGroup = m<UseDeleteIngredientsGroupOutput, UseDeleteIngredientsGroupInput>(
  deleteIngredientGroup
);

type UseGetProductsFromIngredientsOutput = {
  products: {
    totalProducts: number;
    data: Product[];
  };
};

type UseGetProductsFromIngredientsInput = {
  ingredientId: ID;
  offset?: number;
  limit?: number;
};

const getProductsFromIngredients = gql`
  ${productBody}
  query ($ingredientId: ID!, $offset: Int, $limit: Int, $lite: Boolean = true) {
    products: getProductsFromIngredients(ingredientId: $ingredientId, offset: $offset, limit: $limit) {
      totalProducts
      data {
        ...ProductBody
      }
    }
  }
`;

export const useGetProductsFromIngredients = q<UseGetProductsFromIngredientsOutput, UseGetProductsFromIngredientsInput>(
  getProductsFromIngredients
);
