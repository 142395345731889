import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space, Typography } from 'antd';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useProductLifeTags, useProductMessage, useUpdateProductMessage } from '../hooks';
import AddIngredientForm from './AddIngredientForm';
import LifeTags from './LifetagsForm';
import styles from './styles.module.scss';

// http://localhost:3000/products/335920
export interface ITableFooterProps {
  productId: number | string;
}
interface EditConfig {
  editing?: boolean;
  icon?: React.ReactNode;
  tooltip?: boolean | React.ReactNode;
  onStart?: () => void;
  onChange?: (value: string) => void;
  maxLength?: number;
  autoSize?: boolean | AutoSizeType;
}
const TableFooter: FC<ITableFooterProps> = (props) => {
  const { productId } = props;
  const [visible, setVisible] = useState(false);
  const makeVisible = useCallback(() => {
    setVisible(true);
  }, []);
  const close = useCallback(() => {
    setVisible(false);
  }, []);
  const { data: messages, loading: messagesLoading, error: messagesError } = useProductMessage(Number(productId));
  const { data: lifeTags, loading: lifeTagsLoading, error: lifeTagsError } = useProductLifeTags(Number(productId));
  const [updateProductMessage, { loading: updating }] = useUpdateProductMessage();
  useEffect(() => {
    if (messagesError) {
      message.error(messagesError);
    }
  }, [messagesError]);
  useEffect(() => {
    if (lifeTagsError) {
      message.error(lifeTagsError);
    }
  }, [lifeTagsError]);
  const onChangeProductMessage = useCallback(
    (productMessage: string) => {
      updateProductMessage({
        variables: {
          productId: Number(productId),
          message: productMessage,
        },
      }).catch((err) => {
        return message.error(String(err));
      });
    },
    [productId, updateProductMessage]
  );
  const editable = useMemo((): EditConfig => {
    return {
      onChange: onChangeProductMessage,
      icon: <EditOutlined spin={messagesLoading || updating} />,
    };
  }, [messagesLoading, onChangeProductMessage, updating]);

  return (
    <div className={styles.container}>
      <Space direction={'vertical'}>
        <section>
          <h3>Product message: </h3>
          <Typography.Text>{messages?.product.interpretedIngredients?.message || ''}</Typography.Text>
        </section>
        <section>
          <h3>Life tags: </h3>
          <LifeTags
            productId={productId}
            loading={lifeTagsLoading}
            interpretedLifestyles={lifeTags?.product.interpretedLifestyles || null}
          />
        </section>
      </Space>
    </div>
  );
};

export default TableFooter;
