import { gql } from '@apollo/client';
import { m } from '../utils/utils';

type UseStartReindexJobOutput = { startReindexJob: boolean };
export type UseStartReindexJobInput =
  | { groupId: number }
  | { products: Array<{ gln: string; gtin: string; targetMarket?: string }> };
const useStartReindexJobSchema = gql`
  mutation ($products: [ReindexProduct!], $groupId: Float) {
    startReindexJob(config: { products: $products, groupId: $groupId })
  }
`;
export const useStartReindexJob = m<UseStartReindexJobOutput, UseStartReindexJobInput>(useStartReindexJobSchema);

const useRefetchJobSchema = gql`
  mutation ($gtins: [String!], $groupId: Int, $lastChangeDateTimeFrom: String) {
    job: startRefetchJob(config: { gtins: $gtins, groupId: $groupId, lastChangeDateTimeFrom: $lastChangeDateTimeFrom })
  }
`;
export const useRefetchJob = m<UseStartReindexJobOutput, UseStartReindexJobInput>(useRefetchJobSchema);
