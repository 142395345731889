import { QuestionCircleOutlined, QuestionOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { useState } from 'react';

interface HelpModalProps {
  title: string;
  description: string;
}

export function HelpModal(props: HelpModalProps) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Button
        type="ghost"
        shape="circle"
        icon={<QuestionOutlined />}
        onClick={() => {
          setDrawerOpen(true);
        }}
      />
      <Drawer
        title="Help"
        placement="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}>
        <QuestionCircleOutlined style={{ fontSize: '50px', marginBottom: '20px' }} />
        <h2>{props.title}</h2>
        <p>{props.description}</p>
      </Drawer>
    </>
  );
}
