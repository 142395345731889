import { gql } from '@apollo/client';
import { q } from '../utils/utils';
import { ProductAllergenSearch } from 'src/model/ingredients';

const useAllergensSchema = gql`
  query {
    productAllergens: productAllergens(limit: 99999) {
      data {
        name
      }
    }
  }
`;
export const useAllergens = q<{ productAllergens: { data: ProductAllergenSearch[] } }>(useAllergensSchema);
