import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, message, Modal, Row, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { AuthContext } from 'src/components/AuthProvider';
import { HelpModal } from 'src/components/HelpModal';
import { NoPermissionBoundary } from 'src/components/NoPermission/NoPermissionBoundary';
import { withNoPermissionPage } from 'src/components/NoPermission/NoPermissionPage';
import { TagDB } from 'src/model/tag';
import { StringParam, useQueryParam } from 'use-query-params';
import { v4 } from 'uuid';
import { Breadcrumb } from '../../components/Breadcrumb';
import { TagForm } from './components/TagForm';
import { TagProducts } from './components/TagProducts';
import { useCreateTag, useDeleteTag, useTags, useUpdateTag } from './hooks/useTags';

const TableWithData: FC<{
  data: any[];
  setSelected: (id: string) => void;
  setViewing: (value: string) => void;
  refetch: () => void;
}> = ({ data, setSelected, setViewing, refetch }) => {
  const user = useContext(AuthContext);
  const [duplicate] = useCreateTag();
  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render(value: string, recordTagDB: TagDB) {
        return (
          <Row align="middle">
            {(function () {
              if (recordTagDB.isPublic) {
                return (
                  <img
                    src={process.env.PUBLIC_URL + '/F_icon_square_leaf.png'}
                    style={{ maxHeight: '20px', borderRadius: '100%', marginRight: '10px' }}
                    alt="Foodfacts tag logo"
                    title="Foodfacts tag"
                  />
                );
              }
            })()}
            {value}
          </Row>
        );
      },
    },
    {
      dataIndex: 'id',
      key: 'id',
      render(value) {
        return (
          <>
            <Button
              style={{ float: 'right' }}
              onClick={(e) => {
                setViewing(value);
                e.stopPropagation();
              }}>
              View products
            </Button>
            <NoPermissionBoundary
              style={{ float: 'right', marginRight: '10px' }}
              identifyPermission={(permissions) => permissions.searchService.write}>
              <Button
                onClick={(e) => {
                  Modal.confirm({
                    title: 'Are you sure you want to remove the tag?',
                    icon: <ExclamationCircleOutlined />,
                    okText: 'Continue',
                    cancelText: 'Cancel',
                    onOk: () => {
                      _delete({ variables: { id: value } }).then(() => {
                        refetch();
                      });
                    },
                  });

                  e.stopPropagation();
                }}>
                Delete
              </Button>
            </NoPermissionBoundary>
            <NoPermissionBoundary
              style={{ float: 'right', marginRight: '10px' }}
              identifyPermission={(permissions) => permissions.searchService.write}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  duplicate({
                    variables: {
                      values: {
                        name: `NEW_TAG-${v4()}`,
                        query: data.find((tag) => tag.id === value).query,
                      },
                    },
                  }).then(() => {
                    message.success('Successfully duplicated tag');
                    return refetch();
                  });
                }}>
                Duplicate tag
              </Button>
            </NoPermissionBoundary>
          </>
        );
      },
    },
  ];
  const [_delete] = useDeleteTag();

  const onRow = useCallback(
    (data) => {
      return {
        onClick: () => {
          if (user?.userGroup.permissions.searchService.write) {
            setSelected(data.id);
          }
        },
      };
    },
    [setSelected, user?.userGroup.permissions.searchService.write]
  );

  return <Table size={'middle'} dataSource={data} columns={columns} rowKey={'id'} onRow={onRow} />;
};

const TagListPage = () => {
  const [selected, setSelected] = useQueryParam('id', StringParam);
  const [viewing, setViewing] = useQueryParam('view', StringParam);
  const { data: tagsResult, refetch } = useTags({});
  const [update, { loading: updateInProgress }] = useUpdateTag();
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [create] = useCreateTag();

  const selectedTag = tagsResult?.tags.find((tag) => tag.id === selected);
  const viewingTag = tagsResult?.tags.find((tag) => tag.id === viewing);

  const existingTags = useMemo(() => tagsResult?.tags.map((tag) => tag.name) || [], [tagsResult?.tags]);
  return (
    <div>
      <Breadcrumb />
      <Typography.Title level={1}>
        <Row justify="space-between" align="middle">
          Tags
          <HelpModal
            title="Tags page"
            description="From the tags page you get an overview of all the tags in the system. A tag will be granted to a product if it satisfies all the conditions the users specify for the tag.  To see all the products that contain a specific tag, press the “View Products” button. We have already created some tags that are available to all customers, these Foodfacts tags are marked with a Foodfacts logo. To create a new tag, press the “Create new” button and select a name for your new tag. From the “Filters” dropdown you can press the “OR CONDITION” button to add as many conditions to your tag as you wish. From the “key” dropdown you can then select some attributes to filter by. Once you are done click the “Save” button. The tag you just created will now show up on all products that satisfy these conditions!"
          />
        </Row>
      </Typography.Title>
      <Space style={{ marginBottom: 16 }}>
        <NoPermissionBoundary identifyPermission={(permissions) => permissions.searchService.write}>
          <>
            <Button
              onClick={() => {
                setCreateModalOpen(true);
              }}>
              Create new
            </Button>
            <Modal
              title="Create new tag"
              open={isCreateModalOpen}
              okText="Create"
              okButtonProps={{ disabled: newTagName.length === 0 }}
              onOk={() => {
                setCreateModalOpen(false);
                create({ variables: { values: { name: newTagName, query: '{}' } } }).then(() => {
                  message.success('Successfully created new');
                  return refetch();
                });
              }}
              onCancel={() => setCreateModalOpen(false)}>
              <Input
                placeholder="New tag name"
                onChange={(event) => {
                  setNewTagName(event.target.value);
                }}
              />
            </Modal>
          </>
        </NoPermissionBoundary>
      </Space>
      {tagsResult && (
        <TableWithData data={tagsResult.tags} setViewing={setViewing} setSelected={setSelected} refetch={refetch} />
      )}
      {selectedTag && (
        <Drawer
          title={
            <Typography.Text>
              <Row align="middle">
                Viewing {selectedTag.name}
                {(function () {
                  if (selectedTag.isPublic) {
                    return (
                      <img
                        src={process.env.PUBLIC_URL + '/F_icon_square_leaf.png'}
                        style={{ maxHeight: '15px', borderRadius: '100%', marginLeft: '10px' }}
                        alt="Foodfacts tag logo"
                        title="Foodfacts tag"
                      />
                    );
                  }
                })()}
              </Row>
            </Typography.Text>
          }
          placement={'right'}
          closable={true}
          visible={true}
          width={'100vw'}
          onClose={() => setSelected(undefined)}>
          <TagForm
            existingNames={existingTags}
            key={selectedTag.id}
            onSave={(tag) => {
              update({
                variables: {
                  id: tag.id,
                  values: {
                    name: tag.name,
                    query: tag.query,
                    isPublic: tag.isPublic,
                    description: tag.description,
                    publicName: tag.publicName,
                  },
                },
              }).then((res) => {
                const hasError = Object.hasOwn(res, 'error');
                if (res.data && !hasError) {
                  message.success('Updated tag!');
                }
              });
            }}
            loading={updateInProgress}
            tag={selectedTag}
          />
        </Drawer>
      )}
      {viewingTag && (
        <Drawer
          title={<Typography.Text>Viewing products for {viewingTag.name} </Typography.Text>}
          placement={'right'}
          closable={true}
          visible={true}
          width={'100vw'}
          onClose={() => setViewing(undefined)}>
          <TagProducts defaultValues={viewingTag} />
        </Drawer>
      )}
    </div>
  );
};

export default withNoPermissionPage(TagListPage, (permissios) => permissios.searchService.read);
