import React, { FC, useCallback, useMemo } from 'react';
import { Button, Card, Col, Form, InputNumber, notification, Row } from 'antd';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { ProductIngredientNutrient } from '../../../../model/ingredients';
import { notNullGuard } from '../../../../utils/typescript';
import { gql, useMutation } from '@apollo/client';
import { ID } from '../../../../utils/type';
import { ingredientNutrientBody } from '../../../../api/ingredients';
import IngredientInformationSourceSelect from './IngredientInformationSourceSelect';
import UpdateIngredientStyles from '../UpdateIngredient.module.scss';

export interface IngredientNutrientsBlockProps {
  ingredientId: ID;
  nutrients: Array<ProductIngredientNutrient | null>;
}

// Energy (probably best to do 2 fields as it can be in either kcal or kJ or both)

export interface MyNumberInputProps {
  className?: string;
}
const MyNumberInput: FC<MyNumberInputProps> = ({ className, ...restProps }) => {
  return (
    <InputNumber
      {...restProps}
      disabled
      className={classNames(className, styles.numberInput)}
      parser={(value) => (value ? value.replace(',', '.') : '')}
      placeholder={'Amount'}
    />
  );
};

const NUTRIENT_UNIT_TYPES = {
  gram: 1,
  g: 2,
  mg: 36,
  kJ: 44,
  kcal: 46,
  '%': 52,
  ug: 76,
  RE: 80,
  NE: 98,
};
const NUTRIENT_UNIT_ID: Record<NutrientTypeCode, ID> = {
  'ENERKJ-': NUTRIENT_UNIT_TYPES.kJ,
  'ENERKCAL-': NUTRIENT_UNIT_TYPES.kcal,
  FASAT: NUTRIENT_UNIT_TYPES.g,
  CHOAVL: NUTRIENT_UNIT_TYPES.g,
  FIBTG: NUTRIENT_UNIT_TYPES.g,
  FAT: NUTRIENT_UNIT_TYPES.g,
  'SUGAR-': NUTRIENT_UNIT_TYPES.g,
  'PRO-': NUTRIENT_UNIT_TYPES.g,
  SALTEQ: NUTRIENT_UNIT_TYPES.g,
};
// const NUTRIENT_TYPE_ID: Record<MappedKey, ID> = {
//     energiKj: 22,
//     energiKcal: 23,
//     saturatedFat: 1,
//     carbonHydrates: 27,
//     fiber: 30,
//     fett: 31,
//     sugar: 53,
//     protein: 28,
//     salt: 55,
// };

export interface NutrientFormProps {
  label: string;
  type: NutrientTypeCode;
}

type NutrientTypeCode = 'FAT' | 'FASAT' | 'PRO-' | 'SUGAR-' | 'ENERKJ-' | 'ENERKCAL-' | 'CHOAVL' | 'SALTEQ' | 'FIBTG';
const NutrientTypeCodes = [
  'FAT',
  'FASAT',
  'PRO-',
  'SUGAR-',
  'ENERKJ-',
  'ENERKCAL-',
  'CHOAVL',
  'SALTEQ',
  'FIBTG',
] as const;
type FormValues = {
  [key in NutrientTypeCode]: {
    amount: number | null;
    source: ID;
  };
};

interface CreateNutrientValues {
  nutrientAmount: number;
  nutrientTypeCode: NutrientTypeCode;
  nutrientUnitId: ID;
  portionId: ID;
  sourceId?: ID | null;
}

const NutrientField: FC<NutrientFormProps> = (props) => {
  const { label, type } = props;

  return (
    <>
      <Form.Item label={label}>
        <Row>
          <Col xs={24} md={8}>
            <Form.Item name={[type, 'amount']}>
              <MyNumberInput />
            </Form.Item>
          </Col>
          <Col xs={24} md={16}>
            <Form.Item
              name={[type, 'source']}
              rules={[
                ({ getFieldValue }) => ({
                  async validator(_, value) {
                    if (getFieldValue([type, 'amount']) === null) {
                      // the field is not required if amount is not set
                      return;
                    }
                    if (!value) {
                      throw new Error('Please provide the source information');
                    }
                    return;
                  },
                }),
                ({ getFieldValue }) => ({
                  warningOnly: true,
                  async validator(_, value) {
                    if (getFieldValue([type, 'amount']) === null && !!value) {
                      throw new Error('Amount is not set');
                    }
                    return;
                  },
                }),
              ]}>
              <IngredientInformationSourceSelect />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

/**
 *
 *  NOTE: everything here currently is per 100g hardcoded
 */
const IngredientNutrientsBlock: FC<IngredientNutrientsBlockProps> = ({ nutrients, ingredientId }) => {
  const notNulNutrients = useMemo(() => {
    return nutrients.filter(notNullGuard);
  }, [nutrients]);

  const [createNutrientData, { loading }] = useMutation<
    { createIngredientNutrient: unknown[] },
    { ingredientId: ID; values: CreateNutrientValues[] }
  >(
    gql`
      mutation NutrientFormCreateData($ingredientId: ID!, $values: [UpdateNutrientInput]!) {
        createIngredientNutrient(ingredientId: $ingredientId, values: $values) {
          ...IngredientNutrientsBody
        }
      }
      ${ingredientNutrientBody}
    `,
    {
      update: (cache, mutationResult) => {
        const id = cache.identify({ __typename: 'Ingredient', id: ingredientId });
        if (!id) {
          console.warn('Ingredient not found in cache', { ingredientId });
          return;
        }
        cache.writeFragment({
          id: cache.identify({ __typename: 'Ingredient', id: ingredientId }),
          fragment: gql`
            fragment IngredientNutrients on Ingredient {
              nutrients {
                ...IngredientNutrientsBody
              }
            }
            ${ingredientNutrientBody}
          `,
          fragmentName: 'IngredientNutrients',
          data: {
            nutrients: mutationResult.data?.createIngredientNutrient,
          },
        });
      },
    }
  );

  const handleFinish = useCallback(
    (formValues: FormValues) => {
      const values: CreateNutrientValues[] = Object.entries(formValues)
        .map(([key, fieldValue]) => {
          const typedKey = key as NutrientTypeCode;

          return {
            nutrientAmount: fieldValue.amount!, // the `filter` prevents us from sending nulls any way
            sourceId: fieldValue.source,
            nutrientTypeCode: typedKey,
            nutrientUnitId: NUTRIENT_UNIT_ID[typedKey],
            portionId: 1, // per 100g, hardcoded
          };
        })
        .filter(({ nutrientAmount }) => {
          // empty fields should not be sent
          return nutrientAmount !== null;
        });
      createNutrientData({
        variables: {
          ingredientId,
          values,
        },
      })
        .then(() => {
          notification.success({
            message: 'Data updated',
          });
        })
        .catch((err) =>
          notification.error({
            message: err instanceof Error ? err.message : String(err),
          })
        );
    },
    [createNutrientData, ingredientId]
  );

  const mappedTypes: { [key in NutrientTypeCode]: ProductIngredientNutrient | undefined } = useMemo(() => {
    const energiKj = notNulNutrients.find((n) => n.nutrientType === 'Energi (kJ)');
    const energiKcal = notNulNutrients.find((n) => n.nutrientType === 'Energi (kcal)');
    const saturatedFat = notNulNutrients.find((n) => n.nutrientType === 'Summa mättade fettsyror');
    const carbonHydrates = notNulNutrients.find((n) => n.nutrientType === 'Kolhydrater');
    const fiber = notNulNutrients.find((n) => n.nutrientType === 'Fibrer');
    const fett = notNulNutrients.find((n) => n.nutrientType === 'Fett');
    const sugar = notNulNutrients.find((n) => n.nutrientType === 'Socker totalt');
    const protein = notNulNutrients.find((n) => n.nutrientType === 'Protein');
    const salt = notNulNutrients.find((n) => n.nutrientType === 'Salt');

    return {
      'ENERKJ-': energiKj,
      'ENERKCAL-': energiKcal,
      FASAT: saturatedFat,
      CHOAVL: carbonHydrates,
      FIBTG: fiber,
      FAT: fett,
      'SUGAR-': sugar,
      'PRO-': protein,
      SALTEQ: salt,
    };
  }, [notNulNutrients]);
  const initialValues = useMemo(() => {
    return Object.fromEntries(
      NutrientTypeCodes.map((code) => [
        code,
        {
          amount: mappedTypes[code]?.nutrientAmount || null,
          source: mappedTypes[code]?.nutrientSource?.id || null,
        },
      ])
      // FIXME: actually there are some null values, we need to remap the type and lower requirements
    ) as FormValues;
  }, [mappedTypes]);

  return (
    <Card title={'Nutrients'}>
      <Form
        layout={'vertical'}
        onFinish={handleFinish}
        initialValues={initialValues}
        className={UpdateIngredientStyles.readonlyForm}>
        <Row gutter={[20, 0]}>
          <Col xs={24} md={12}>
            <NutrientField label={'Energi (kJ)'} type={'ENERKJ-'} />
          </Col>
          <Col xs={24} md={12}>
            <NutrientField label={'Energi (kcal)'} type={'ENERKCAL-'} />
          </Col>
          <Col xs={24} md={12}>
            <NutrientField label={'Fett'} type={'FAT'} />
          </Col>
          <Col xs={24} md={12}>
            <NutrientField label={'Summa mättade fettsyror'} type={'FASAT'} />
          </Col>
          <Col xs={24} md={12}>
            <NutrientField label={'Kolhydrater'} type={'CHOAVL'} />
          </Col>
          <Col xs={24} md={12}>
            <NutrientField label={'Socker totalt'} type={'SUGAR-'} />
          </Col>
          <Col xs={24} md={12}>
            <NutrientField label={'Fibrer'} type={'FIBTG'} />
          </Col>

          <Col xs={24} md={12}>
            <NutrientField label={'Protein'} type={'PRO-'} />
          </Col>
          <Col xs={24} md={12}>
            <NutrientField label={'Salt'} type={'SALTEQ'} />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default IngredientNutrientsBlock;
