import { gql } from '@apollo/client';
import { IngredientInformation, IngredientInformationSource } from 'src/model/ingredientInfromation';
import { ProductIngredient } from 'src/model/ingredients';
import { ID } from 'src/utils/type';
import { m, q } from 'src/utils/utils';
import { ingredientBody } from './ingredients';

type IngredientInformationInput = Omit<IngredientInformation, 'id'>;
type IngredientInformationSourceInput = Omit<IngredientInformationSource, 'id'>;
type UseCreateIngredientInformationInput = { values: IngredientInformationInput };
type UseCreateIngredientInformationOutput = { ingredientInformation: IngredientInformation };
const useCreateIngredientInformationSchema = gql`
  mutation ($values: ProductIngredientInformationInput!) {
    ingredientInformation: createIngredientInformation(values: $values) {
      id
      type
      body
      isPublic
      source {
        id
        name
        website
        iconUrl
      }
    }
  }
`;
export const useCreateIngredientInformation = m<
  UseCreateIngredientInformationOutput,
  UseCreateIngredientInformationInput
>(useCreateIngredientInformationSchema);

type UseGetIngredientInformationsSourcesOutput = {
  sources: {
    data: IngredientInformationSource[];
    totalAmount: number;
  };
};
const useGetIngredientsSourcesSchema = gql`
  query {
    sources: getIngredientInformationSources {
      data {
        name
        website
        iconUrl
        id
      }
    }
  }
`;
export const useIngredientInformationSources =
  q<UseGetIngredientInformationsSourcesOutput>(useGetIngredientsSourcesSchema);

type UseCreateIngredientInformationsSourceOutput = {
  ingredientInformationSource: IngredientInformationSource;
};
type UseCreateIngredientInformationSourceInput = { values: IngredientInformationSourceInput };
const useCreateIngredientsSourcesSchema = gql`
  mutation ($values: InformationSourceInput!) {
    ingredientInformationSource: createIngredientInformationSource(values: $values) {
      id
      name
      website
      iconUrl
    }
  }
`;

export const useCreateIngredientInformationSources = m<
  UseCreateIngredientInformationsSourceOutput,
  UseCreateIngredientInformationSourceInput
>(useCreateIngredientsSourcesSchema);

type UseUpdateIngredientInformationsSourceOutput = {
  ingredientInformationSource: IngredientInformationSource;
};
type UseUpdateIngredientInformationSourceInput = { id: ID; values: IngredientInformationSourceInput };
const useUpdateIngredientsSourcesSchema = gql`
  mutation ($id: ID!, $values: InformationSourceInput!) {
    ingredientInformationSource: updateIngredientInformationSource(id: $id, values: $values) {
      id
      name
      website
      iconUrl
    }
  }
`;
export const useUpdateIngredientInformationSources = m<
  UseUpdateIngredientInformationsSourceOutput,
  UseUpdateIngredientInformationSourceInput
>(useUpdateIngredientsSourcesSchema);

type UseDeleteIngredientInformationSourceInput = { id: ID };
const useDeleteIngredientsSourcesSchema = gql`
  mutation ($id: ID!) {
    deleteIngredientInformationSource(id: $id)
  }
`;
export const useDeleteIngredientInformationSources = m<boolean, UseDeleteIngredientInformationSourceInput>(
  useDeleteIngredientsSourcesSchema
);

type UseConnectIngredientInformationsSourceOutput = {
  ingredient: ProductIngredient;
};
type UseConnectIngredientInformationSourceInput = { ingredientId: ID; informationId: ID };
const useConnectIngredientsSourcesSchema = gql`
  ${ingredientBody}
  mutation ($ingredientId: ID!, $informationId: ID!) {
    ingredient: connectIngredientInformationWithProduct(ingredientId: $ingredientId, informationId: $informationId) {
      ...IngredientBody
    }
  }
`;
export const useConnectIngredientInformationSources = m<
  UseConnectIngredientInformationsSourceOutput,
  UseConnectIngredientInformationSourceInput
>(useConnectIngredientsSourcesSchema);

//
type UseDeleteIngredientInformationsOutput = { ok: boolean };
type UseDeleteIngredientInformationsInput = { id: ID };
const useDeleteIngredientInformationSchema = gql`
  mutation ($id: ID!) {
    ok: deleteIngredientInformation(id: $id)
  }
`;
export const useDeleteIngredientInformation = m<
  UseDeleteIngredientInformationsOutput,
  UseDeleteIngredientInformationsInput
>(useDeleteIngredientInformationSchema);
