import { Popover, Spin } from 'antd';
import { CSSProperties, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext, SiteUser } from '../AuthProvider';
import style from './NoPermissionBoundary.module.scss';

interface NoPermissionProps {
  identifyPermission: (permissions: SiteUser['userGroup']['permissions']) => boolean;
  className?: string;
  style?: CSSProperties;
  children: JSX.Element;
}

export function NoPermissionBoundary(props: NoPermissionProps) {
  const user = useContext(AuthContext);

  if (user) {
    const hasPermission = props.identifyPermission(user.userGroup.permissions);

    if (hasPermission) {
      return (
        <div style={props.style} className={props.className}>
          {props.children}
        </div>
      );
    } else {
      return (
        <Popover
          placement="topLeft"
          className={style['tooltip']}
          title="No permission"
          content={
            <>
              <p>
                Upgrade your current plan
                <br /> to get access for this feature.
              </p>
            </>
          }>
          <div style={props.style} className={props.className}>
            {props.children}
          </div>
        </Popover>
      );
    }
  } else {
    return <Spin />;
  }
}
