import React, { FC, useMemo, useState } from 'react';
import { Select } from 'antd';
import { nutrients } from '../utils/nutrients';

const patchedOptions = nutrients.map((nutrient) => ({
  value: nutrient['Code value'],
  label: nutrient['Name'],
}));

const mainOptionKeys = new Set(['FAT', 'FASAT', ' PRO-', 'SUGAR-', 'ENER-', 'CHOAVL', 'SALTEQ', 'FIBTG']);
const mainOptions = patchedOptions.filter((option) => mainOptionKeys.has(option.value));
export interface NutrientsSelectorProps {
  value: string | null;
  onChange: (value: string) => void;
}
export const NutrientsSelector: FC<NutrientsSelectorProps> = (props) => {
  const { value, onChange } = props;
  const [searchStr, setSearchStr] = useState('');
  const options = useMemo(() => {
    const searchStrLowerCase = searchStr.toLowerCase();
    return searchStrLowerCase
      ? patchedOptions.filter((o) => o.label.toLowerCase().startsWith(searchStrLowerCase))
      : mainOptions;
  }, [searchStr]);

  return (
    <Select
      placeholder={'Nutrient'}
      options={options}
      value={value}
      onChange={onChange}
      showSearch={true}
      searchValue={searchStr}
      onSearch={setSearchStr}
    />
  );
};
