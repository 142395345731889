import React, { FC } from 'react';
import { IInterpretedLifeTags } from '../../../../model/product';
import { LifeStyleTags as LifeStyleTagsImplementation } from '../../../IngredientsPage/InterpretedIngredientList/cells/LifeStyleTags';

export interface LifestyleTagsProps {
  interpretedLifestyles?: IInterpretedLifeTags | null;
}

export const LifestyleTags: FC<LifestyleTagsProps> = (props) => {
  const { interpretedLifestyles } = props;
  if (!interpretedLifestyles) {
    return null;
  }
  const { isLactoVegetarian, isOvoVegetarian, isPescetarian, isVegan, isVegetarian } = interpretedLifestyles;
  return (
    <LifeStyleTagsImplementation
      isLactoVegetarian={isLactoVegetarian}
      isOvoVegetarian={isOvoVegetarian}
      isPescetarian={isPescetarian}
      isVegan={isVegan}
      isVegetarian={isVegetarian}
      bordered={true}
    />
  );
};
