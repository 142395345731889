interface IEnv {
  REACT_APP_STAGE: string;
  PUBLIC_URL: string;
  NODE_ENV: 'development' | 'production' | 'test';
  REACT_APP_PRODUCTS_ENDPOINT: string;
  REACT_APP_FEDERATIONS_ENDPOINT: string;
  REACT_APP_COGNITO_CLIENT_ID: string;
  REACT_APP_COGNITO_USERPOOL_ID: string;
  REACT_APP_BUILD_VERSION: string;
}

export const env = process.env as unknown as IEnv;
