import { gql } from '@apollo/client';
import { q } from '../utils/utils';
import { InformationProvider } from '../model/product';

/** */
type UseInformationProvidersOutput = { informationProviders: InformationProvider[] };
const useInformationProvidersSchema = gql`
  query informationProviders {
    informationProviders {
      gln
      name
    }
  }
`;
export const useInformationProviders = q<UseInformationProvidersOutput>(useInformationProvidersSchema);
