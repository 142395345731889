import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Col, Divider, Empty, Row, Spin, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { useLazyAdvancedProductSearch, useLazyAdvancedProductSearchTotal } from 'src/api/product';
import { HelpModal } from 'src/components/HelpModal';
import { IconText } from 'src/components/IconText';
import { SearchBar } from 'src/components/SearchBar/SearchBar';
import { OrStatement } from 'src/model/productQuery';
import { JsonParam, useQueryParam } from 'use-query-params';
import { Breadcrumb } from '../../components/Breadcrumb';
import { ProductList } from '../../components/ProductList';

const QueryPage = () => {
  const [productsVisible, setProductsVisible] = useState(true);
  const [offset, setOffset] = useState<number>(0);
  const limit = 5;
  const [filter, setFilter] = useQueryParam<OrStatement>('filter', JsonParam);

  const [getProducts, { data, loading, fetchMore }] = useLazyAdvancedProductSearch({
    variables: {
      filter,
      offset,
      limit,
      lite: true,
    },
  });

  const [getTotal, { data: totalData, loading: totalLoading }] = useLazyAdvancedProductSearchTotal({
    variables: {
      filter,
    },
  });

  const products = data?.response.products;
  const total = totalData?.response.total;
  const hasMore = data?.response.hasMore;

  const handleSubmit = useCallback(
    (filter) => {
      setOffset(0);
      setFilter(filter);
      getProducts();
      getTotal();
    },
    [getProducts, getTotal, setFilter]
  );
  return (
    <div>
      <Breadcrumb />
      <Row>
        <Col md={24} lg={18}>
          <Typography.Title>
            <Row justify="space-between" align="middle">
              Find products
              <HelpModal
                title="Search page"
                description="From the default search screen, you can search for products either by searching on the GTIN number of the product or by the products name. From the Advanced search you can further customize your search by adding a filter to the search. Press the &#34;And statement&#34; button to add as many conditions as you want to your search and select an attribute to search by in the &#34;Key&#34; field and then select which option for the attribute in the &#34;Compare&#34; field. The resulting search will return a combination of all products that satisfy all the conditions specified."
              />
            </Row>
          </Typography.Title>
          <SearchBar
            defaultValues={filter}
            loading={loading}
            singleRow
            onSubmit={handleSubmit}
            onClear={(empty) => {
              setProductsVisible(empty);
            }}
          />
          {(function () {
            if (productsVisible && (totalLoading || total)) {
              return <div style={{ marginTop: '10px' }}>Total: {totalLoading ? 'Still counting...' : total}</div>;
            }
          })()}
          <Divider />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col md={24} lg={18}>
          {(function () {
            if (productsVisible) {
              if (loading) {
                return <Spin />;
              } else if (products) {
                return (
                  <ProductList
                    actions={(product) => {
                      const ApprovalIcon = () => {
                        if (product.interpretedIngredients?.approval?.ok === true)
                          return <CheckCircleFilled style={{ color: 'green' }} />;
                        if (product.interpretedIngredients?.approval?.ok === false)
                          return <CloseCircleFilled style={{ color: 'red' }} />;

                        return <CloseCircleFilled style={{ color: 'grey' }} />;
                      };
                      return [<IconText icon={ApprovalIcon} text="Product is approved" key="list-vertical-star-o" />];
                    }}
                    hasMore={hasMore}
                    loadMore={() => {
                      const dataLength = data?.response.products.length;
                      if (dataLength) setOffset(dataLength);
                      fetchMore({ variables: { filter, offset: dataLength, limit, lite: true } });
                    }}
                    products={products}
                  />
                );
              } else {
                return <Empty description={<></>} />;
              }
            }
          })()}
        </Col>
      </Row>
    </div>
  );
};

export default QueryPage;
