import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Select, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import { HelpModal } from 'src/components/HelpModal';
import { BooleanParam, NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useCreateIngredients, useGetIngredients } from '../../api/ingredients';
import { Breadcrumb } from '../../components/Breadcrumb';
import { ProductIngredient, YesNoMaybe } from '../../model/ingredients';
import UpdateIngredient from './UpdateIngredient';

const { useForm } = Form;
const IngredientListPage = () => {
  const pageSize = 20;
  const [viewConfig, setViewConfig] = useState(false);
  const [refetching, setRefetching] = useState(false);
  const [query, setQuery] = useQueryParam<string>('query', StringParam as any); // FIXME: actual type
  const [page, setPage] = useQueryParam<number>('page', NumberParam as any); // FIXME: actual type
  const [asc, setAsc] = useQueryParam('asc', withDefault(BooleanParam, true)); // FIXME: actual type
  const [sortField, setFieldSort] = useQueryParam<'name' | 'creationDateTime'>('sortField', StringParam as any); // FIXME: actual type
  const { data, fetchMore, error, loading, refetch } = useGetIngredients({
    variables: {
      sortField,
      asc: asc === true,
      limit: pageSize,
      offset: pageSize * Math.max(0, page - 1),
      q: (query?.length > 0 ? query : undefined)!, // FIXME: undefined
    },
  });
  const [selectedId, setSelectedId] = useQueryParam<number | undefined>('selected', NumberParam as any); // FIXME: actual type
  const [createNew, setCreateNew] = useQueryParam<boolean>('createNew', BooleanParam as any); // FIXME: actual type

  const [create, { loading: isCreating }] = useCreateIngredients();

  useEffect(() => {
    if (query !== undefined) {
      setRefetching(true);
      refetch({
        asc: asc === true,
        q: query?.length > 0 ? query : undefined,
        limit: pageSize,
        offset: 0,
        sortField,
      }).finally(() => {
        setRefetching(false);
      });
    }
  }, [query, refetch, asc, sortField]);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  const columns: ColumnsType<ProductIngredient> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Vegan',
      dataIndex: 'isVegan',
      key: 'isVegan',
      render: (ing: YesNoMaybe, _) => {
        return <Checkbox indeterminate={ing === YesNoMaybe.maybe} checked={ing !== YesNoMaybe.no} />;
      },
    },
    {
      title: 'Vegetarian',
      dataIndex: 'isVegetarian',
      key: 'isVegetarian',
      render: (ing: YesNoMaybe, _) => {
        return <Checkbox indeterminate={ing === YesNoMaybe.maybe} checked={ing !== YesNoMaybe.no} />;
      },
    },
    {
      title: 'Lacto Vegetarian',
      dataIndex: 'isLactoVegetarian',
      key: 'isLactoVegetarian',
      render: (ing: YesNoMaybe, _) => {
        return <Checkbox indeterminate={ing === YesNoMaybe.maybe} checked={ing !== YesNoMaybe.no} />;
      },
    },
    {
      title: 'Ovo Vegetarian',
      dataIndex: 'isOvoVegetarian',
      key: 'isOvoVegetarian',
      render: (ing: YesNoMaybe, _) => {
        return <Checkbox indeterminate={ing === YesNoMaybe.maybe} checked={ing !== YesNoMaybe.no} />;
      },
    },
    {
      title: 'Pescetarian',
      dataIndex: 'isPescetarian',
      key: 'isPescetarian',
      render: (ing: YesNoMaybe, _) => {
        return <Checkbox indeterminate={ing === YesNoMaybe.maybe} checked={ing !== YesNoMaybe.no} />;
      },
    },
    {
      title: 'Changed by',
      dataIndex: 'lastChangedBy',
      key: 'lastChangedBy',
      render: (email: string, _) => {
        return email || '-';
      },
    },
  ];

  const loadMore = (offset: number) => {
    setRefetching(true);
    fetchMore({
      variables: {
        offset: offset,
        limit: pageSize,
      },
    }).finally(() => {
      setRefetching(false);
    });
  };

  const SearchConfig = useCallback(() => {
    return (
      <>
        <Form.Item name="asc" label="Order result">
          <Select defaultValue={asc.toString()}>
            <Select.Option value="true">Ascending</Select.Option>
            <Select.Option value="false">Descending</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="sortField" label="Sort on field">
          <Select defaultValue={sortField}>
            <Select.Option value="name">Ingredient name</Select.Option>
            <Select.Option value="creationDateTime">Creation date</Select.Option>
          </Select>
        </Form.Item>
      </>
    );
  }, [asc, sortField]);

  const Title = useCallback(() => {
    return (
      <Row gutter={[20, 0]} justify="start">
        <Form
          onFinish={(form) => {
            if (form.asc) {
              setAsc(form.asc === 'true');
            }
            if (form.sortField) {
              setFieldSort(form.sortField);
            }
            setPage(1);
            setQuery(form.query);
          }}
          initialValues={{ query }}>
          <Typography.Title level={1}>
            <Row justify="space-between" align="middle">
              Ingredients
              <HelpModal
                title="Ingredients page"
                description="From the ingredient screen you can see all the ingredients that are available in the FoodFacts database. From the overview you can see the ingredients name and the health tags. If you click an ingredient you are taken to a new page with more information about the ingredient."
              />
            </Row>
          </Typography.Title>
          <Row gutter={[10, 0]}>
            <Col>
              <Form.Item name="query">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col>
              <Button onClick={() => setViewConfig(!viewConfig)}>Advanced search</Button>
            </Col>
            <Col>
              <Button htmlType="submit">Search</Button>
            </Col>

            <Col hidden={!viewConfig} span={24}>
              <SearchConfig />
            </Col>
          </Row>
        </Form>
      </Row>
    );
  }, [SearchConfig, query, setAsc, setFieldSort, setPage, setQuery, viewConfig]);

  const onSave = useCallback(
    (form: ProductIngredient) => {
      create({ variables: { values: form } })
        .then((newIngredientResult) => {
          message.success('Successfully created new product ingredient!');
          refetch().then(() => {
            setCreateNew(false);

            if (newIngredientResult.data) {
              setQuery(newIngredientResult.data.newIngredient.name, 'replaceIn');
              setSelectedId(newIngredientResult.data.newIngredient.id as number, 'replaceIn');
            }
          });
        })
        .catch((error) => {
          message.error(error.message);
        });
    },
    [create, refetch, setCreateNew, setQuery, setSelectedId]
  );

  const AddNewModal = ({ visible }: { visible: boolean }) => {
    const [form] = useForm();

    return (
      <Modal
        onCancel={() => setCreateNew(false)}
        onOk={() => {
          form.submit();
        }}
        visible={visible}>
        <Typography.Title>New ingredient</Typography.Title>
        <Form onFinish={onSave} form={form}>
          <Form.Item label="Ingredient name" name="name">
            <Input disabled={isCreating} required />
          </Form.Item>
          {isCreating && <Spin />}
        </Form>
      </Modal>
    );
  };

  const handleCloseEditing = useCallback(
    (shouldReFetch = false) => {
      setSelectedId(undefined);
      if (shouldReFetch) {
        refetch();
      }
    },
    [refetch, setSelectedId]
  );

  return (
    <div>
      <Breadcrumb />

      {AddNewModal({ visible: !!createNew })}
      {selectedId && <UpdateIngredient ingredientId={selectedId} onClose={handleCloseEditing} />}

      <Table
        loading={loading || refetching}
        title={Title}
        sticky
        pagination={{
          onChange: (page, pageSize) => {
            loadMore((page - 1) * (pageSize as any)); // FIXME: undefined
            setPage(page);
          },
          defaultCurrent: page,
          position: ['bottomCenter'],
          pageSize,
          showSizeChanger: false,

          total: data?.ingredients.totalAmount,
        }}
        dataSource={data?.ingredients.data}
        rowKey={'id'}
        columns={columns}
        onRow={(data) => {
          return {
            style: {
              cursor: 'pointer',
            },
            onClick: () => {
              setSelectedId(data.id as number);
            },
          };
        }}
      />
    </div>
  );
};

export default IngredientListPage;
