import { Form, Input } from 'antd';
import React from 'react';
import { useContext } from 'react';
import { IsEditingContext } from './context';

export interface IEditableFieldProps<R extends object, F extends keyof R> {
  record: R;
  fieldName: F;
  value: string;
}

export function EditableField<R extends object, F extends keyof R>(props: IEditableFieldProps<R, F>): JSX.Element {
  const { fieldName, value } = props;
  const isEditing = useContext(IsEditingContext);
  if (!isEditing) {
    return <>{value}</>;
  }
  return (
    <Form.Item name={String(fieldName)} noStyle={true}>
      <Input />
    </Form.Item>
  );
}
