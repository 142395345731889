import { Result, Spin } from 'antd';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext, SiteUser } from '../AuthProvider';

export function withNoPermissionPage(
  PageComponent: () => JSX.Element,
  identifyPermission: (permissions: SiteUser['userGroup']['permissions']) => boolean
) {
  return function NoPermissionPage() {
    const user = useContext(AuthContext);

    if (typeof user === 'object') {
      const hasPermission = identifyPermission(user.userGroup.permissions);

      if (hasPermission) {
        return <PageComponent />;
      } else {
        return (
          <Result
            status="403"
            title="No permission"
            subTitle="Upgrade your current plan to get access for this page."
          />
        );
      }
    } else {
      return <Spin />;
    }
  };
}
