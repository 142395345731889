import React, { FC, useState } from 'react';
import { FileImageTwoTone } from '@ant-design/icons';

export const Image: FC<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = (
  props
) => {
  const [showReplacement, setShowReplacement] = useState(false);

  return (
    <>
      {!showReplacement && props.src && (
        <img
          {...props}
          onError={(e) => {
            console.warn('Not able to load image...');
            if (!showReplacement) setShowReplacement(true);
          }}
          alt=""
        />
      )}
      {(showReplacement || !props.src) && (
        <FileImageTwoTone
          {...(props as any)}
          style={{ ...(props.style || {}), fontSize: '60px' }}
          twoToneColor={['grey', 'white']}
        />
      )}
    </>
  );
};
