import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Col, Form, message, Row, Select, Tree, TreeProps, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import produce from 'immer';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAddChildGroup, useGroupSearch, useRemoveChildGroup } from 'src/api/group';
import { AuthContext } from 'src/components/AuthProvider';
import { Breadcrumb } from 'src/components/Breadcrumb';
import { NoPermissionBoundary } from 'src/components/NoPermission/NoPermissionBoundary';
import { withNoPermissionPage } from 'src/components/NoPermission/NoPermissionPage';
import { IdentifierGroup } from 'src/model/group';
import { ArrayElement, notGuard, notNullGuard } from 'src/utils/typescript';
import { ROUTER_PAGES } from '../../routes';
import style from './GroupHierarchy.module.scss';

type TreeSelectOptionType = ArrayElement<NonNullable<TreeProps['treeData']>>;
export const buildTreeData = (
  v: IdentifierGroup,
  path: React.Key[] = [],
  appendix?: (group: IdentifierGroup, isRoot: boolean, key: React.Key[]) => JSX.Element | undefined,
  extraDataNode?: (path: React.Key[]) => TreeSelectOptionType
): TreeSelectOptionType | null => {
  if (!v) return null;

  const key = path.join('-');
  const children = v.children?.data
    ?.map((v) => buildTreeData(v, [...path, v.id], appendix, extraDataNode))
    .filter(notNullGuard);
  const isRoot = path.length === 1;
  const amountOfChildren = v.totalProducts;

  return {
    selectable: false,
    key: [...path].join('-'),
    title: (
      <NoPermissionBoundary identifyPermission={(permissions) => permissions.productService.write}>
        <>
          {v.name}
          {` (${amountOfChildren}) `}
          {appendix?.(v, isRoot, [key])}
        </>
      </NoPermissionBoundary>
    ),
    children: [...(children || []), extraDataNode?.(path)].filter(notGuard),
  };
};

export const replaceKey = (
  nodes: IdentifierGroup[],
  id: number,
  mutate: (group: IdentifierGroup) => IdentifierGroup
): IdentifierGroup[] => {
  return nodes.map((node) => {
    if (node.id === id) {
      return produce(node, (n) => mutate(n));
    } else {
      const children = node.children?.data;

      return {
        ...node,
        children: node.children
          ? {
              total: node.children.total,
              data: children ? replaceKey(children, id, mutate) : undefined,
            }
          : undefined,
      };
    }
  });
};

export const getIdFromKey = (keyPath: React.Key, offset = 0): number => {
  const list = keyPath.toString().split('-');
  const item = list[list.length - 1 - offset];

  if (!item) {
    throw new Error('Not able to find id from key...');
  }

  return Number.parseInt(item);
};

const SearchGroupField = () => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const { data, loading } = useGroupSearch({
    variables: { startsWith: searchTerm },
  });

  return (
    <Form.Item required label="Child group" name={'childId'}>
      <Select
        onClear={() => setSearchTerm(undefined)}
        loading={loading}
        onSearch={debounce((e) => {
          setSearchTerm(e);
        }, 500)}
        value={searchTerm}
        placeholder="Enter a group..."
        allowClear={true}
        filterOption={false}
        showSearch>
        {data?.groups.filter(notGuard).map((v) => {
          return (
            <Select.Option key={v.id} value={v.id}>
              {v.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

function GroupHierarchy() {
  const user = useContext(AuthContext);
  const [addGroupId, setAddGroupId] = useState<number>();
  const [form] = useForm<any>();
  const { data } = useGroupSearch();

  const [_add] = useAddChildGroup();
  const [_delete] = useRemoveChildGroup();

  const onDelete = useCallback(
    (e: React.Key[]) => {
      // Is delete event
      const key = getIdFromKey(e[0]);
      const parent = getIdFromKey(e[0], 1);

      _delete({
        variables: {
          childId: key,
          parentId: parent,
        },
      });
    },
    [_delete]
  );

  const treeData = useMemo(() => {
    const nodesWithParents = new Set<number>();
    const handleNode = (node: IdentifierGroup, fromParent: boolean): void => {
      if (fromParent) {
        nodesWithParents.add(node.id);
      }
      if (node.children?.data) {
        node.children.data.forEach((child) => handleNode(child, true));
      }
    };
    data?.groups?.forEach((rootNode) => handleNode(rootNode, false));

    return data?.groups
      ?.filter((v) => !nodesWithParents.has(v.id)) // filter out sub-groups from the Root list
      .map((v) =>
        buildTreeData(
          v,
          [v.id],
          (v, isRoot, key) => {
            return !isRoot ? (
              <DeleteOutlined
                className={style['delete-button']}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete?.(key);
                }}
                style={{ float: 'right' }}
              />
            ) : undefined;
          },
          (path) => ({
            selectable: false,
            className: style['add-row'],
            isLeaf: true,
            key: [...path, 'add'].join('-'),
            title: (
              <NoPermissionBoundary identifyPermission={(permissions) => permissions.productService.write}>
                <Typography.Text
                  strong
                  style={{ fontSize: '20px', height: '50px' }}
                  onClick={() => {
                    setAddGroupId(Number(path[path.length - 1]));
                  }}>
                  add new...
                  <PlusCircleOutlined className={style['add-button']} />
                </Typography.Text>
              </NoPermissionBoundary>
            ),
          })
        )
      )
      .filter(notNullGuard);
  }, [data?.groups, onDelete]);

  const history = useHistory();

  const handleClick: NonNullable<TreeProps['onClick']> = useCallback(
    (e, node) => {
      if (user?.userGroup.permissions.productService.write) {
        const pathToNode = String(node.key).split('-');
        const lastKey = pathToNode[pathToNode.length - 1];
        const isRegularNode = lastKey && !!Number(lastKey);
        if (isRegularNode) {
          history.push(`${ROUTER_PAGES.editGroups.path.replace(':id', String(lastKey))}`);
        }
      }
    },
    [history, user?.userGroup.permissions.productService.write]
  );

  return (
    <div>
      <Breadcrumb />
      <Typography.Title level={1}>Group Hierarchy</Typography.Title>
      <Modal
        onOk={() => {
          form.submit();
        }}
        visible={addGroupId !== undefined}
        onCancel={() => setAddGroupId(undefined)}>
        <Typography.Title level={3}>Adding to group</Typography.Title>
        <Form
          onFinish={(form) => {
            if (addGroupId && form.childId)
              _add({
                variables: {
                  childId: form.childId,
                  parentId: addGroupId,
                },
              })
                .catch((err) => {
                  message.error(err.message);
                })
                .then((res) => {
                  if (!res) return;
                  message.success('Added group to group');
                  setAddGroupId(undefined);
                });
          }}
          form={form}>
          <SearchGroupField />
        </Form>
      </Modal>

      <Row style={{ width: '100%' }} gutter={[20, 0]}>
        <Col sm={24} md={12}>
          <Tree motion={false} onClick={handleClick} blockNode treeData={treeData} />
        </Col>
      </Row>
    </div>
  );
}

export default withNoPermissionPage(GroupHierarchy, (permission) => permission.productService.read);
