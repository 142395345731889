import { gql } from '@apollo/client';
import { Product, ProductLite } from '../model/product';
import { ContainmentLevel } from '../model/tag';
import { lazyQuery, m, q } from '../utils/utils';
import { ID } from '../utils/type';
import { ProductQuery } from 'src/model/productQuery';

export const LIFE_TAGS = gql`
  fragment LifeTagFields on Lifestyles {
    id
    isLactoVegetarian
    isVegan
    isVegetarian
    isOvoVegetarian
    isPescetarian
  }
`;

export const HIT_FIELDS = gql`
  fragment ingHit on IngredientProductHit {
    id
    eco
    origin
    foundName
    jaccard
    descriptions
    amount
    ingredient {
      id
      name
      isLactoVegetarian
      isOvoVegetarian
      isPescetarian
      isVegan
      isVegetarian
    }
  }
`;

export const ING_HIT_CHILDREN = gql`
  fragment ingHitChildren on IngredientProductHit {
    ...ingHit
    children {
      ...ingHit
      children {
        ...ingHit
        children {
          ...ingHit
          children {
            ...ingHit
            children {
              ...ingHit
              children {
                ...ingHit
                children {
                  ...ingHit
                  children {
                    ...ingHit
                    children {
                      ...ingHit
                      children {
                        ...ingHit
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${HIT_FIELDS}
`;

// Fragments
export const productBody = gql`
  ${ING_HIT_CHILDREN}

  fragment ProductBody on Product {
    name
    id
    tags
    brandName
    gtin
    interpretationIngredientStatement

    productDates @skip(if: $lite) {
      availableToDateAndTime
      launchDateAndTime
      deliveryDateAndTime
      availableFromDateAndTime
    }

    interpretedAllergens @skip(if: $lite) {
      data {
        allergen
        allergenLevel
        reason
      }
    }

    productImage(size: large) {
      url
    }

    interpretedLifestyles {
      ...LifeTagFields
    }
    interpretedIngredients {
      id
      average
      message
      approval {
        id
        ok
        message
        lastChangedBy
      }

      data @skip(if: $lite) {
        ...ingHitChildren
      }
    }

    productIdentifier {
      externalId
      targetMarket {
        name
      }
    }
    isConsumerUnit
    informationProvider {
      name
      gln
    }
    ingredientInformation @skip(if: $lite) {
      ingredientStatement
    }
    sourceLastChangedDateAndTime
    creationDateAndTime
    modifiedDateAndTime
    sourceLastChangedDateAndTime
    gpc @skip(if: $lite) {
      code
      text
    }
    allergenInformation @skip(if: $lite) {
      statement
      allergens {
        containmentCode {
          name
          code
        }
        typeCode {
          name
        }
      }
    }
    markings @skip(if: $lite) {
      healthRelated {
        allergenCodes {
          code
          name
        }
        dietCodes {
          code
          name
        }
        dietSubCategoryCodes {
          code
          name
        }
        freeFromCodes {
          code
          name
        }
      }
      accreditations {
        accreditationCodes {
          name
          info {
            shortDescription
            logo {
              url
            }
          }
        }
      }

      tags {
        allergens {
          tag
          name
          gs1Code {
            code
            name
          }
          containmentCode
        }
        healthRelated {
          tag
          name
          description
        }
      }
    }
    packageInformation @skip(if: $lite) {
      measurement {
        size
      }
    }
    marketingInformation @skip(if: $lite) {
      marketingMessages
    }
    consumerInformation @skip(if: $lite) {
      usageInstructions
      storageInstructions
    }
    fishAndAquacultureInformation @skip(if: $lite) {
      speciesCode {
        code
        latinName
        englishName
      }
      storageState {
        code
        name
      }
      catchMethods {
        code
        name
      }
    }
    originInformation @skip(if: $lite) {
      country {
        name
      }
      originArea
      originDeclaration
      activityType {
        code
        name
      }
      activityArea {
        code
        name
      }
    }

    productTags @skip(if: $lite) {
      name
      id
    }

    nutrientsInformation @skip(if: $lite) {
      nutriScore
      nutrientStatements
      detailedNutriScore {
        energy
        sugar
        salt
        fat
        protein
        fiber
        fruit
        sweeteners
        positive
        negative
        total

        input {
          foodType
          lookupTableKey
        }
      }
      content {
        nutrientBasisQuantityTypeCode {
          name
        }
        nutrientBasisQuantity {
          value
          measurementUnitCode {
            name
          }
        }
        nutrients {
          typeCode {
            name
          }
          measurementPrecisionCode {
            name
          }
          quantityContained {
            value
            measurementUnitCode {
              name
            }
          }
          dailyValueIntakePercent
        }
      }
    }
  }

  ${LIFE_TAGS}
`;

export interface ProductSearchQuery {
  informationProviderGln?: string[];
  gpc?: string[];
  brandNames?: string[];
  name?: string;
  offset?: number;
  limit?: number;
  groupId?: number;
  accreditedMarking?: string[];
}

export type ProductSearchResult = {
  productsSearch: {
    products: ProductLite[];
    total: number;
    start: number;
    end: number;
  };
};

const useProductSearchQuerySchema = gql`
  ${productBody}
  # Write your query or mutation here
  query ProductSearch(
    $offset: Int
    $limit: Int
    $name: String
    $gpc: [String!]
    $groupId: Int
    $brandNames: [String!]
    $informationProviderGln: [String!]
    $lastChangedFrom: DateTime
    $launchDateFrom: DateTime
    $accreditedMarking: [String!]
    $lite: Boolean = true
  ) {
    productsSearch(
      offset: $offset
      limit: $limit
      name: $name
      gpc: $gpc
      groupId: $groupId
      brandNames: $brandNames
      informationProviderGln: $informationProviderGln
      showNonConsumerProducts: false
      uniqueGtin: true
      launchDateFrom: $launchDateFrom
      lastChangedFrom: $lastChangedFrom
      accreditedMarking: $accreditedMarking
    ) {
      products {
        ...ProductBody
      }
      total
      start
      end
    }
  }
`;

export const useProductSearchQuery = q<ProductSearchResult, ProductSearchQuery>(useProductSearchQuerySchema);

const useProductByGtinSchema = gql`
  ${productBody}
  query GetProductQuery($gtins: [String!]!, $lite: Boolean = false) {
    productsByGTINs(gtins: $gtins) {
      ...ProductBody
    }
  }
`;

export const useProductByGtin = q<{ productsByGTINs: Product[] }, { gtins: string[] }>(useProductByGtinSchema);

const useContainmentLevelsSchema = gql`
  query {
    getContainmentLevel {
      code
    }
  }
`;
export const useContainmentLevels = q<{ getContainmentLevel: ContainmentLevel[] }>(useContainmentLevelsSchema);

const useProductSchema = gql`
  query product($id: Int!, $lite: Boolean = false) {
    product(id: $id) {
      ...ProductBody
    }
  }
  ${productBody}
`;
export const useProduct = q<{ product: Product }, { id: number }>(useProductSchema);

const useProductBrandNamesSchema = gql`
  query productBrandNames {
    productBrandNames
  }
`;
export const useProductBrandNames = q<{ productBrandNames: string[] }>(useProductBrandNamesSchema);

const useDeleteProductSchema = gql`
  mutation ($id: ID!) {
    deleted: deleteProduct(id: $id)
  }
`;
export const useDeleteProduct = m<{ deleted: boolean }, { id: ID }>(useDeleteProductSchema);

const useAdvancedProductSearchSchema = gql`
  ${productBody}
  query ($filter: ProductSearchFilter, $limit: Int, $offset: Int, $lite: Boolean = true) {
    response: products(filter: $filter, limit: $limit, offset: $offset) {
      products {
        ...ProductBody
      }
      hasMore
    }
  }
`;

export type AdvancedProductSearchParams = {
  filter?: ProductQuery;
  limit?: number;
  offset?: number;
  lite?: boolean;
};

export const useAdvancedProductSearch = q<
  { response: { products: Product[]; hasMore: boolean } },
  AdvancedProductSearchParams
>(useAdvancedProductSearchSchema);

export const useLazyAdvancedProductSearch = lazyQuery<
  { response: { products: Product[]; hasMore: boolean } },
  AdvancedProductSearchParams
>(useAdvancedProductSearchSchema);

const useAdvancedProductSearchTotalSchema = gql`
  query ($filter: ProductSearchFilter) {
    response: products(filter: $filter) {
      total
    }
  }
`;

export const useAdvancedProductSearchTotal = q<
  { response: { total: number } },
  Omit<AdvancedProductSearchParams, 'offset' | 'limit'>
>(useAdvancedProductSearchTotalSchema);

export const useLazyAdvancedProductSearchTotal = lazyQuery<
  { response: { total: number } },
  Omit<AdvancedProductSearchParams, 'offset' | 'limit'>
>(useAdvancedProductSearchTotalSchema);

const useAdvancedProductSearchCsvSchema = gql`
  query ($filter: ProductSearchFilter, $limit: Int, $offset: Int) {
    response: products(filter: $filter, limit: $limit, offset: $offset) {
      csv
    }
  }
`;
export const useAdvancedProductSearchCsv = q<
  { response: { products: Product[]; hasMore: boolean } },
  AdvancedProductSearchParams
>(useAdvancedProductSearchCsvSchema);
