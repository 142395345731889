import { Form, Modal, Typography, Row, Col } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
// import { APPLY_PRODUCT_CHANGES, useProduct } from '../../api/product';
// import { Product, ProductChangeEvent } from '../../model/product';

// import { useMutation } from '@apollo/client';

const EditProductPage = () => {
  const history = useHistory();
  // const { data } = useProduct(Number(params.id));
  // const [applyChanges] = useMutation<{ applyProductChanges: Product }>(APPLY_PRODUCT_CHANGES);
  const [form] = Form.useForm();

  // const [deleteEvents, setDeleteEvents] = useState<ProductChangeEvent[]>([]);

  // const onFormSubmit = values => {
  //     let insertAllergenEvents: ProductChangeEvent[] = [];
  //     if (values.allergen_tags) {
  //         insertAllergenEvents = values.allergen_tags
  //             .map(tag => {
  //                 const allergenTag: AllergenTag = { tag: tag.allergen, containmentCode: tag.containment };
  //                 return allergenTag;
  //             })
  //             .map(allergenTag => ({
  //                 event: ProductEvent.INSERT_ALLERGEN_TAG,
  //                 payload: JSON.stringify(allergenTag)
  //             }));
  //     }

  //     let insertHealthEvents: ProductChangeEvent[] = [];
  //     if (values.health_tags) {
  //         insertAllergenEvents = values.health_tags
  //             .map(tag => {
  //                 const healthTag: HealthTag = { tag: tag.health_tag };
  //                 return healthTag;
  //             })
  //             .map(healthTag => ({
  //                 event: ProductEvent.INSERT_HEALTH_TAG,
  //                 payload: JSON.stringify(healthTag)
  //             }));
  //     }

  //     const productChangeEvents = deleteEvents.concat(insertHealthEvents).concat(insertAllergenEvents);
  //     if (productChangeEvents.length > 0) {
  //         //TODO add a confirmation?
  //         applyChanges({
  //             variables: {
  //                 id: Number(data.product.id),
  //                 events: productChangeEvents
  //             }
  //         })
  //             .then(response => {
  //                 message.info(
  //                     'Tags have been successfully changed on product ' + response.data.applyProductChanges.id
  //                 );
  //                 history.goBack();
  //             })
  //             .catch(err => {
  //                 message.error(err.message);
  //             });
  //     } else {
  //         history.goBack();
  //     }
  // };

  const onFormReset = () => {
    history.goBack();
  };

  return (
    <Modal
      width={'90vw'}
      visible={true}
      title="Edit allergens"
      onCancel={onFormReset}
      onOk={() => form.submit()}
      okText={'Save'}>
      <Typography.Title>Edit product</Typography.Title>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          {/* <Form form={form} name="product-edit-form" onFinish={onFormSubmit} layout={'vertical'}>
                        {data?.product.markings?.tags.allergens.length > 0 && (
                            <MarkingsAllergenTagsCardContent
                                tags={data?.product.markings?.tags}
                                productId={Number(params.id)}
                                editable={true}
                                onTagDelete={tag => {
                                    const allergenTag: AllergenTag = {
                                        tag: tag.tag,
                                        containmentCode: tag.containmentCode
                                    };
                                    setDeleteEvents([
                                        ...deleteEvents,
                                        {
                                            event: ProductEvent.DELETE_ALLERGEN_TAG,
                                            payload: JSON.stringify(allergenTag)
                                        }
                                    ]);
                                }}
                            />
                        )}
                        <br></br>
                        <br></br>

                        <Form.Item>
                            <AddAllergenTagForm
                                allergens={allergenTags?.getAllergenTags}
                                containmentsData={containmentLevels?.getContainmentLevel}
                                containmentMode="single"
                                translate={true}
                            />
                        </Form.Item>
                    </Form>
                </Col> */}
          {/* <Col span={12}>
                    <Form form={form} name="product-edit-form" onFinish={onFormSubmit} layout={'vertical'}>
                        {data?.product.markings?.tags.allergens.length > 0 && (
                            <MarkingsHealthRelatedTagsCardContent
                                tags={data?.product.markings?.tags}
                                productId={Number(params.id)}
                                editable={true}
                                onTagDelete={tag => {
                                    const healthTag: HealthTag = { tag: tag.tag };
                                    console.log(healthTag);
                                    console.log(deleteEvents);
                                    setDeleteEvents([
                                        ...deleteEvents,
                                        { event: ProductEvent.DELETE_HEALTH_TAG, payload: JSON.stringify(healthTag) }
                                    ]);
                                }}
                            />
                        )}
                        <br></br>
                        <br></br>
                        <Form.Item>
                            <AddHealthTagForm healthTags={healthTags?.getHealthTags} />
                        </Form.Item>
                    </Form> */}
        </Col>
      </Row>
    </Modal>
  );
};

export default EditProductPage;
