import React, { CSSProperties, FC, useMemo } from 'react';
import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { ID } from 'src/utils/type';
import { notNullGuard } from '../../../../../utils/typescript';

const query = gql`
  query IngredientInformationSourceSelect {
    getIngredientInformationSources {
      data {
        id
        name
      }
    }
  }
`;

export interface IngredientInformationSourceSelectResult {
  getIngredientInformationSources: null | {
    data: null | Array<null | {
      id: ID | null;
      name: string | null;
    }>;
  };
}

export interface IngredientInformationSourceSelectProps {
  value?: ID;
  style?: CSSProperties;
}

const IngredientInformationSourceSelect: FC<IngredientInformationSourceSelectProps> = (props) => {
  const { style, ...restProps } = props;
  const { data, loading } = useQuery<IngredientInformationSourceSelectResult>(query);

  const options = useMemo(() => {
    if (!data || !data.getIngredientInformationSources || !data.getIngredientInformationSources.data) {
      return [];
    }

    return data.getIngredientInformationSources.data
      .filter(notNullGuard)
      .filter((elem) => !!elem.id && !!elem.name)
      .map((elem) => ({
        value: String(elem.id),
        label: String(elem.name),
      }));
  }, [data]);

  return (
    <Select
      {...restProps}
      disabled
      allowClear={true}
      options={options}
      loading={loading}
      style={{ ...style, width: '100%' }}
      placeholder={'Source'}
    />
  );
};

export default IngredientInformationSourceSelect;
