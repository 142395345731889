import * as H from 'history';

export const URLSearchParamsToObject = <T>(params: URLSearchParams) => {
  const a: any = {};

  params.forEach((val, key) => {
    try {
      if (val) a[key] = JSON.parse(val);
    } catch {
      if (val) a[key] = val;
    }
  });

  return a as T;
};

export const URLFromObject = (object: Record<string, any>, prevParams?: URLSearchParams) => {
  const a: any = prevParams ? { ...URLSearchParamsToObject(prevParams), ...object } : { ...object };

  const params = new URLSearchParams();

  for (const key in a) {
    if (a[key]) params.append(key, JSON.stringify(a[key]));
  }
  return params as URLSearchParams;
};

export function setQueryParams(location: H.Location, queryParams: Record<string, string>): string {
  const searchParams = new URLSearchParams(location.search.replace('?', ''));
  Object.entries(queryParams).forEach(([key, value]) => {
    searchParams.set(key, value);
  });
  return `${location.pathname}?${searchParams.toString()}`;
}
