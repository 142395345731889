import { gql, useQuery, QueryHookOptions, QueryResult } from '@apollo/client';
import { Tag } from '../model/tag';

export const GET_ALLERGEN_TAGS = gql`
  query getAllergenTags {
    getAllergenTags {
      tag
      name
      description
    }
  }
`;

export const GET_ALLERGEN_TAG = gql`
  query getAllergenTag($tag: String!) {
    getAllergenTag(tag: $tag) {
      tag
      name
      description
      gs1Code {
        code
      }
    }
  }
`;

export const SAVE_ALLERGEN_TAG = gql`
  mutation saveAllergenTag($tag: TagInput!) {
    saveAllergenTag(tag: $tag) {
      tag
      name
      description
      gs1Code {
        code
      }
    }
  }
`;

export const DELETE_ALLERGEN_TAG = gql`
  mutation deleteAllergenTag($tag: String!) {
    deleteAllergenTag(tag: $tag) {
      tag
      name
      description
      gs1Code {
        code
      }
    }
  }
`;

export const SAVE_HEALTH_TAG = gql`
  mutation saveHealthTag($tag: TagInput!) {
    saveHealthTag(tag: $tag) {
      tag
      name
      description
    }
  }
`;

export const DELETE_HEALTH_TAG = gql`
  mutation deleteHealthTag($tag: String!) {
    deleteHealthTag(tag: $tag) {
      tag
      name
      description
    }
  }
`;

export const GET_HEALTH_TAGS = gql`
  query getHealthTags {
    getHealthTags {
      name
      tag
      description
      gs1Code {
        code
      }
    }
  }
`;

export const GET_HEALTH_TAG = gql`
  query getHealthTag($tag: String!) {
    getHealthTag(tag: $tag) {
      name
      tag
      description
    }
  }
`;

// export const useAllergenTags = (opt?: QueryHookOptions<{ getAllergenTags: Tag[] }>) => {
//     return useQuery<{ getAllergenTags: Tag[] }>(GET_ALLERGEN_TAGS);
// };

export const useHealthTags = (
  opt?: QueryHookOptions<{ getHealthTags: Tag[] }>
): QueryResult<{ getHealthTags: Tag[] }> => {
  return useQuery(GET_HEALTH_TAGS);
};

export const useHealthTag = (tag: string): QueryResult<{ getHealthTag: Tag }, { tag: string }> => {
  return useQuery(GET_HEALTH_TAG, { variables: { tag: tag } });
};

// export const useAllergenTag = (tag: string) => {
//     return useQuery<{ getAllergenTag: Tag }>(GET_ALLERGEN_TAG, { variables: { tag: tag } });
// };
