import { gql } from '@apollo/client';
import { VolumeEstimate } from 'src/model/volumeEstimate';
import { m, q } from 'src/utils/utils';

const useVolumeEstimateSchema = gql`
  query ($productId: Int!) {
    product(id: $productId) {
      volumeEstimates {
        ingredientId
        ingredientName
        estimate
      }
    }
  }
`;

export const useVolumeEstimate = q<{ product: { volumeEstimates: VolumeEstimate[] } }, { productId: number }>(
  useVolumeEstimateSchema,
  { fetchPolicy: 'no-cache' }
);

const useRecalculateVolumeEstimateSchema = gql`
  mutation ($productId: ID!) {
    recalculateVolumeEstimate(id: $productId)
  }
`;

export const useRecalculateVolumeEstimate = m<{ recalculateVolumeEstimate: boolean }, { productId: number }>(
  useRecalculateVolumeEstimateSchema
);
