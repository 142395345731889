import { FC } from 'react';
import { useVolumeEstimate } from 'src/api/volumeEstimate';
import { Column } from '@ant-design/plots';
import { Card } from 'antd';
import { colors } from 'src/styles/colors';

interface VolumeEstimateProps {
  productId: number;
}

const VolumeEstimateCard: FC<VolumeEstimateProps> = (props) => {
  const { productId } = props;
  const { data, loading } = useVolumeEstimate({ variables: { productId: Number(productId) } });

  return (
    <>
      {(loading || data?.product?.volumeEstimates) && (
        <Card title="Ingredients volume estimate" loading={loading}>
          {data?.product?.volumeEstimates && (
            <Column
              {...{
                data:
                  data.product.volumeEstimates.length > 0
                    ? data.product.volumeEstimates.map((v) => {
                        // values under 1% should be 0%
                        return {
                          type: v.ingredientName,
                          estimate: v.estimate >= 0.01 ? v.estimate : 0,
                        };
                      })
                    : [],
                xField: 'type',
                yField: 'estimate',
                color: colors.foodfactsGreen,
                maxColumnWidth: 50,
                xAxis: {
                  label: {
                    autoHide: false,
                    autoRotate: true,
                  },
                },
                meta: {
                  estimate: {
                    formatter: (data: any) => {
                      return data * 100 + '%';
                    },
                  },
                },
                tooltip: {
                  formatter: (data: any) => {
                    // values under 1% should be displayed as < 1%
                    const percentage = data.estimate < 0.01 ? '< 1%' : `${(data.estimate * 100).toFixed(2)}%`;
                    return { name: data.ingredientName, value: percentage };
                  },
                },
              }}
            />
          )}
        </Card>
      )}
    </>
  );
};

export default VolumeEstimateCard;
