import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Tabs } from 'antd';
import { useState } from 'react';
import { OrStatement } from 'src/model/productQuery';
import { HistorySearch } from './HistorySearch';
import SearchFilter from './SearchFilter';

interface AdvancedSearchProps {
  query?: OrStatement;
  onQueryChange: (query: OrStatement) => void;
}

const defaultQuery: OrStatement = {
  or: [
    {
      and: [],
    },
  ],
};
export const AdvancedSearch = (props: AdvancedSearchProps) => {
  const { query = defaultQuery, onQueryChange } = props;
  console.log(query);
  return (
    <>
      <SearchFilter query={query} onChange={onQueryChange} />
    </>
  );
};

interface ISearchBar {
  onSubmit?: (query: OrStatement) => void;
  defaultValues?: OrStatement;
  singleRow?: boolean;
  loading?: boolean;

  extraActions?: JSX.Element | JSX.Element[];
  onClear: (empty: boolean) => void;
}

export const SearchBar = (props: ISearchBar) => {
  const { onSubmit, defaultValues, loading, extraActions } = props;
  const [query, setQuery] = useState<OrStatement | undefined>(defaultValues);
  const [defaultInput, setDefaultInput] = useState('');

  return (
    <Form
      layout={'vertical'}
      initialValues={{ limit: 10, offset: 10 }}
      onFinish={() => {
        props.onClear(true);
        if (query) onSubmit?.(query);
      }}>
      <Tabs
        tabBarExtraContent={extraActions}
        destroyInactiveTabPane
        onChange={(activeKey: string) => {
          switch (activeKey) {
            case 'default':
            case 'advanced':
              props.onClear(true);
              break;
            case 'history':
              props.onClear(false);
              break;
          }
        }}>
        <Tabs.TabPane tab="Default search" key="default">
          <Row gutter={[10, 10]}>
            <Col span={20}>
              <Input
                value={defaultInput}
                style={{ height: '50px' }}
                onChange={(event) => {
                  setDefaultInput(event.target.value);
                  setQuery({
                    or: [
                      {
                        and: [
                          {
                            brandName: {
                              includes: event.target.value,
                            },
                          },
                        ],
                      },
                      {
                        and: [
                          {
                            name: {
                              includes: event.target.value,
                            },
                          },
                        ],
                      },
                      {
                        and: [
                          {
                            gtin: {
                              endsWith: event.target.value,
                            },
                          },
                        ],
                      },
                    ],
                  });
                }}
                placeholder="Search by GTIN or part of the product name"
              />
            </Col>
            <Col span={4}>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                style={{ height: '50px' }}
                htmlType="submit"
                block={true}
                loading={loading}>
                Search
              </Button>
            </Col>
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Advanced search" key="advanced">
          <Row gutter={[10, 10]}>
            <Col span={20}>
              <AdvancedSearch key="1" query={query} onQueryChange={setQuery} />
            </Col>
            <Col span={4}>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                size="small"
                style={{ height: '22.5px', marginBottom: '5px' }}
                htmlType="submit"
                block={true}
                loading={loading}>
                Search
              </Button>
              <Button
                size="small"
                style={{ height: '22.5px' }}
                onClick={() => {
                  setQuery({ or: [{ and: [] }] });
                  setDefaultInput('');
                }}
                icon={<ClearOutlined />}
                block={true}
                loading={loading}>
                Clear
              </Button>
            </Col>
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane tab="History search" key="history">
          <HistorySearch />
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};
