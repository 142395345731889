import MDEditor from '@uiw/react-md-editor';
import { Card } from 'antd';
import Empty from 'antd/es/empty';
import { useState } from 'react';
import { useDeleteIngredientInformation } from 'src/api/ingredient-information';
import { IngredientInformation } from 'src/model/ingredientInfromation';
import { ID } from 'src/utils/type';

interface InformationListProps {
  ingredientInfromation: IngredientInformation[];
}
export const InformationList = (props: InformationListProps) => {
  const { ingredientInfromation } = props;
  const [deletedStatements, setDeletedStatements] = useState<ID[]>([]);
  const [deleteIngredient] = useDeleteIngredientInformation();
  // Stores a local copy of the removed since they are not keep
  // track of using the API
  const notDeletedIngredientInfromation = ingredientInfromation.filter((v) => !deletedStatements.includes(v.id));

  return (
    <>
      {notDeletedIngredientInfromation.length === 0 && <Empty />}
      {notDeletedIngredientInfromation.map((ingredientInfo) => {
        return (
          <Card key={ingredientInfo.id} title={ingredientInfo.source?.name} style={{ marginBottom: '10px' }}>
            {ingredientInfo.type === 'RICH_TEXT' && (
              <MDEditor.Markdown source={JSON.parse(ingredientInfo.body)['richText']} />
            )}
          </Card>
        );
      })}
    </>
  );
};
