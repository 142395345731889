import React, { FC } from 'react';
import { Badge } from 'antd';
import { colors } from '../../../../styles/colors';

interface JaccardBadgeProps {
  score: number;
}
export const JaccardBadge: FC<JaccardBadgeProps> = (props) => {
  const { score: jaccard } = props;
  return (
    <>
      Similarity score{' '}
      <Badge
        count={jaccard}
        style={{
          backgroundColor: jaccard === 1 ? colors.success : jaccard === 0 ? colors.error : colors.warning,
        }}
      />
    </>
  );
};
