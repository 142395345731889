import { Avatar, Button, Col, Layout, Popover, Row, Typography } from 'antd';
import { Auth } from 'aws-amplify';
import { FC, useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { client } from '../client';
import { ROUTER_PAGES } from '../routes';
import { AuthContext } from './AuthProvider';

const { Header } = Layout;

const MainHeader = withRouter(({ history, location }) => {
  const [userInfo, setUserInfo] = useState<any>(null); // FIXME: actual type
  const user = useContext(AuthContext);

  useEffect(() => {
    if (location.pathname.startsWith('/public/')) return;
    if (location.pathname !== ROUTER_PAGES.signIn.path) {
      // checking on every route change, to be certain that the userInfo we have is still correct
      Auth.currentUserInfo().then((info) => {
        setUserInfo(info);
        if (!info) {
          history.push(ROUTER_PAGES.signIn.path);
          //TODO we need to know which path the user came from, so he/she can be redirected there after login
        }
      });
    }
  }, [location, history]);

  if (location.pathname === ROUTER_PAGES.signIn.path) return <></>;

  const UserContent: FC = () => {
    return (
      <div>
        <Row>
          <Col span={24}>
            <Typography.Title type="secondary" level={4}>{`Logged in as ${userInfo?.username}`}</Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button
              type="primary"
              onClick={(event) => {
                event.preventDefault();
                Auth.signOut({}).then(() => {
                  setUserInfo(null);
                  client.resetStore().finally();
                  history.push(ROUTER_PAGES.signIn.path);
                });
              }}
              style={{ width: '100%' }}>
              Sign out
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div
      className="header-wrapper"
      style={{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
      }}>
      <Header style={{ backgroundColor: 'white' }}>
        <Row className="header" align="middle">
          <Col lg={8} flex="auto">
            <div style={{ height: '40px' }}>
              <Link to="/">
                <Typography.Title level={4} style={{ height: '40px' }}>
                  <img
                    src={process.env.PUBLIC_URL + '/F_icon_square_leaf.png'}
                    style={{ maxHeight: '40px', borderRadius: '100%', marginRight: '10px' }}
                    alt="Foodfacts logo"
                  />
                  Foodfacts
                </Typography.Title>
              </Link>
            </div>
          </Col>
          <Col flex="auto" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '15px' }}>
            <span>{user?.userGroup.name}</span>
            <Popover content={UserContent} trigger="hover" placement="bottomRight">
              <Avatar size={40} className={'header-avatar'}>
                {userInfo?.username?.charAt(0).toUpperCase() || 'GUEST'}
              </Avatar>
            </Popover>
          </Col>
        </Row>
      </Header>
    </div>
  );
});

export default MainHeader;
