import { Breadcrumb as AntDBreadcrumb } from 'antd';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

export const Breadcrumb: FC = () => {
  const history = useHistory();

  return (
    <>
      <AntDBreadcrumb style={{ margin: '16px 0' }}>
        <AntDBreadcrumb.Item href="/">Foodfacts</AntDBreadcrumb.Item>
        {history.location.pathname.split('/').map((v, index, arr) => {
          const href = arr.slice(0, index + 1).join('/');
          return (
            <AntDBreadcrumb.Item
              href={href}
              onClick={(event) => {
                // we don't want a page reload, so using history to navigate.
                event.preventDefault();
                history.push(href);
              }}
              key={index}>
              {v}
            </AntDBreadcrumb.Item>
          );
        })}
      </AntDBreadcrumb>
    </>
  );
};
