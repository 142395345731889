import { Card, Col, Divider, message, Row, Spin, Typography } from 'antd';
import { FC, useCallback, useState } from 'react';
import { useInterpretProductIngredients } from '../../../api/ingredients';
import { Product } from '../../../model/product';
import { InterpretedIngredientList } from '../../../pages/IngredientsPage/InterpretedIngredientList';
import { ApprovedBadge } from '../ApproveBadge';
import { LifeStyleTagsInfo } from '../LifeStyleTagsInfo';

interface ProductIngredientsProps {
  nutrientsInformation: Product['nutrientsInformation'];
  id: number;
  isAdmin: boolean;
  isPublic: boolean;
  interpretedIngredients: Product['interpretedIngredients'];
  interpretationIngredientStatement: Product['interpretationIngredientStatement'];
  ingredientStatement: string | undefined;
  interpretedLifestyles: Product['interpretedLifestyles'];
}
const ProductIngredients: FC<ProductIngredientsProps> = (props) => {
  const {
    nutrientsInformation,
    isAdmin,
    isPublic,
    id,
    interpretedIngredients,
    ingredientStatement,
    interpretationIngredientStatement,
    interpretedLifestyles,
  } = props;

  const [interpretIngredients, { loading: interpreting }] = useInterpretProductIngredients();
  const interpret = useCallback(
    (newIngredientsStatement?: string) => {
      interpretIngredients({
        variables: {
          id,
          ingredientStatement: newIngredientsStatement,
        },
      }).catch((err) => {
        return message.error(String(err));
      });
    },
    [interpretIngredients, id]
  );

  const updateIngredientsString = useCallback(
    (newIngredientsStatement: string) => {
      interpret(newIngredientsStatement);
    },
    [interpret]
  );

  const restore = useCallback(() => {
    interpret();
  }, [interpret]);

  const ingredientsListEditable = !interpretedIngredients?.approval?.ok && isAdmin;
  const ingredientsListOverwritten =
    !!interpretationIngredientStatement && interpretationIngredientStatement !== ingredientStatement;

  const [showOriginalStatement, setShowOriginalStatement] = useState(false);
  return (
    <Row gutter={[20, 20]}>
      {nutrientsInformation && nutrientsInformation.nutrientStatements.length > 0 && (
        <Col span={24}>
          <Card title="Nutrient statements">
            {nutrientsInformation.nutrientStatements.map((statement, index) => (
              <p key={index}>{statement}</p>
            ))}
          </Card>
        </Col>
      )}

      <Col span={24}>
        <ApprovedBadge approved={interpretedIngredients?.approval || null} productId={id}>
          <Card title="Ingredients">
            <Spin spinning={interpreting}>
              <Typography.Text>
                {isPublic ? ingredientStatement : interpretationIngredientStatement || ingredientStatement}
              </Typography.Text>
            </Spin>
            {interpretedIngredients && (
              <>
                <Divider />
                <Typography.Title level={5}>
                  {(function () {
                    if (isAdmin) {
                      return 'Structured Ingredient list';
                    } else {
                      return 'Interpreted ingredients';
                    }
                  })()}
                </Typography.Title>
                <div
                  style={{
                    maxHeight: '50vh',
                    marginTop: '20px',
                    overflowY: 'scroll',
                  }}>
                  <InterpretedIngredientList
                    isExpandable={isAdmin}
                    originalIngredients={ingredientStatement || ''}
                    ingredients={interpretedIngredients.data}
                    productId={id}
                    disabled={interpretedIngredients.approval?.ok === true || !isAdmin}
                  />
                  {interpretedIngredients.message && (
                    <>
                      <Divider />
                      <p>
                        <b>Product message: </b>
                        {interpretedIngredients.message}
                      </p>
                    </>
                  )}
                  <Divider />
                  <LifeStyleTagsInfo interpretedLifestyles={interpretedLifestyles} />
                </div>
              </>
            )}
          </Card>
        </ApprovedBadge>
      </Col>
    </Row>
  );
};

export default ProductIngredients;
