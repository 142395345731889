import React, { useState } from 'react';
import { Button, Card, Col, Form, message, Row, Select, Typography, Input, Modal, Upload } from 'antd';
import { Breadcrumb } from '../../components/Breadcrumb';

import { FileSyncOutlined, InboxOutlined, SaveOutlined } from '@ant-design/icons/lib';
import { useHistory } from 'react-router-dom';

import TextArea from 'antd/lib/input/TextArea';
import { ROUTER_PAGES } from '../../routes';
import { uniqBy } from 'lodash';
import { useCreateGroup } from '../../api/group';
import { cleanGtins } from 'src/utils/cleanGtins';
import { gtinValidator } from 'src/utils/gtin-validator';

const { Dragger } = Upload;

const parseFile = (text: string, rowSeperator: string, colSeperator: string): string[][] => {
  const rows = text.trim().split(rowSeperator);
  const out = uniqBy(
    rows.map((v) => v.split(colSeperator)),
    0
  );

  return out;
};

const AddNewGroupPage: React.FC = () => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [fileUploadModalVisible, setFileUploadModalVisible] = useState(false);
  const [idsToText, setIdsToText] = useState<Record<string, string>>({});
  const [rowSeperator] = useState('\n');
  const [colSeperator] = useState(',');
  const [createGroup, { loading }] = useCreateGroup();

  const onFormSubmit = (formData: any): void => {
    const { externalIds } = formData;

    createGroup({
      variables: { ...formData, externalIds: externalIds.map(cleanGtins) },
    })
      .then((response) => {
        if (response.data) history.push(ROUTER_PAGES.groups.path);
      })
      .catch((err) => {
        if (err.message.startsWith('ER_DUP_ENTRY')) {
          message.error('Name already exists');
        } else {
          message.error(err.message);
        }
      });
  };

  const onFormReset = (): void => {
    form.resetFields();
  };

  const onFileUpload = (): void => {
    setFileUploadModalVisible(false);
    for (const id in idsToText) {
      const content = idsToText[id];

      try {
        const parsedFile = parseFile(content, rowSeperator, colSeperator);
        const values = parsedFile.flat();
        const prevExternalIds = form.getFieldValue('externalIds') || [];
        form.setFieldsValue({ externalIds: [...prevExternalIds, ...values] });
      } catch {
        message.error('Not able to parse the CSV file with id ' + id + ' see log for more info');
        console.log({ message: 'Not able to parse file with id ' + id, content, idsToText, id });
      }
    }
  };

  return (
    <div>
      <Breadcrumb />

      <Modal
        closable={false}
        title={'Upload CSV file'}
        visible={fileUploadModalVisible}
        onOk={onFileUpload}
        onCancel={() => setFileUploadModalVisible(false)}>
        <Dragger
          onRemove={(e) => {
            const copy: any = {};
            for (const key in idsToText) {
              if (key !== e.uid) {
                copy[key] = idsToText[key];
              }
            }
            setIdsToText(copy);
          }}
          beforeUpload={(file) => {
            file
              .text()
              .then((text) => {
                setIdsToText({ ...idsToText, [file.uid]: text });
              })
              .catch(() => {
                message.error('Not able to parse file into text');
              });
            return false;
          }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <Typography.Paragraph>Click or drag file to this area to upload</Typography.Paragraph>
        </Dragger>
      </Modal>

      <Typography.Title level={1}>New Group</Typography.Title>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Card>
            <Form
              labelCol={{ span: 3 }}
              form={form}
              name="subscription-form"
              onFinish={onFormSubmit}
              initialValues={{ externalIds: [] }}>
              <Form.Item
                required
                name="name"
                label="Group name"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Please enter a name');
                    },
                  }),
                ]}>
                <Input placeholder="Project A"></Input>
              </Form.Item>

              <Form.Item name="description" label="Description">
                <TextArea placeholder="Small description of the group"></TextArea>
              </Form.Item>

              <Form.Item name="externalIds" label="GTINs" rules={[{ validator: gtinValidator }]}>
                <Select
                  mode="tags"
                  placeholder="Enter or paste in a comma separated gtin list"
                  tokenSeparators={[',', '\n']}
                  maxTagCount={5}
                  allowClear={true}></Select>
              </Form.Item>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => setFileUploadModalVisible(true)} style={{ marginRight: '10px' }}>
                  Upload CSV file <FileSyncOutlined />
                </Button>
                <Button onClick={onFormReset} style={{ marginRight: '10px' }}>
                  Reset
                </Button>
                <Button htmlType={'submit'} type={'primary'}>
                  Save <SaveOutlined spin={loading}></SaveOutlined>
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddNewGroupPage;
