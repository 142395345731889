import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';

export const gtinValidator = (rule: RuleObject, value: StoreValue): Promise<void> => {
  if (!value) {
    return Promise.resolve();
  }
  const gtinRegexp = /^[0-9]{8,14}$/;
  const gtins = value as string[];
  const errors = gtins.filter((gtin) => !gtin.trim().match(gtinRegexp)).map((gtin) => `"${gtin}" is not a GTIN`);
  if (errors.length === 0) {
    return Promise.resolve();
  }
  return Promise.reject(errors);
};
