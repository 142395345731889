import React from 'react';
import { useParams } from 'react-router-dom';
import { useProductByGtin } from '../../api/product';
import { Col, Row } from 'antd';
import { ProductViewer } from '../../components/ProductViewer/ProductViewer';
import { parse } from './ProductPage';

const ProductsComparePage = () => {
  const params = useParams<{ gtin: string }>();
  const { data: products } = useProductByGtin({ variables: { gtins: [params.gtin] } });
  return (
    <div>
      <Row gutter={[20, 0]}>
        {products?.productsByGTINs.map((product, index) => (
          <Col
            lg={24 / products.productsByGTINs.length}
            md={24}
            sm={24}
            xs={24}
            key={index}
            style={product.informationProvider.gln === '7300009070992' ? { backgroundColor: '#00000014' } : undefined}>
            <ProductViewer compact data={parse(product)} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProductsComparePage;
