import {
  ApolloCache,
  DocumentNode,
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { ReplaceKey } from './type';

export const q = <OUT, IN = null>(schema: DocumentNode, defaultArgs: QueryHookOptions<OUT, IN> = {}) => {
  return (args: QueryHookOptions<OUT, IN> = {}) => {
    return useQuery<OUT, IN>(schema, {
      ...defaultArgs,
      ...args,
    });
  };
};

export const lazyQuery = <OUT, IN = null>(schema: DocumentNode, defaultArgs: QueryHookOptions<OUT, IN> = {}) => {
  return (args: QueryHookOptions<OUT, IN> = {}) => {
    return useLazyQuery<OUT, IN>(schema, {
      ...defaultArgs,
      ...args,
    });
  };
};

type UpdateFnType<OUT, IN> = (
  cache: ApolloCache<OUT>,
  mutationResult: FetchResult<OUT>,
  input: MConfig<OUT, IN>
) => void;

type MConfig<OUT, IN> = ReplaceKey<MutationHookOptions<OUT, IN>, 'update', UpdateFnType<OUT, IN>>;

export function m<OUT, IN = null>(schema: DocumentNode, defaultArgs: MConfig<OUT, IN> = {}) {
  return (args: MConfig<OUT, IN> = {}) => {
    const config = { ...defaultArgs, ...args };

    const [e, resultingConfig] = useMutation<OUT, IN>(schema, {
      ...config,
      update: (cache, result) => {
        return config?.update?.(cache, result, config);
      },
    });

    const onExecute = (
      options: MutationFunctionOptions<OUT, IN>
    ): Promise<FetchResult<OUT, Record<string, any>, Record<string, any>>> => {
      if (options && !options.update) {
        options.update = (cache, result) => {
          return config?.update?.(cache, result, { ...config, ...options } as any);
        };
      }

      return e(options);
    };

    return [onExecute, resultingConfig] as MutationTuple<OUT, IN>;
  };
}

export async function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
