import { Tooltip, Typography } from 'antd';
import { InterpretedIngredient } from 'src/model/ingredients';

interface IngredientProps {
  item: InterpretedIngredient;
}
export function Ingredient(props: IngredientProps) {
  return (
    <Tooltip
      title={
        <>
          <b>{props.item.ingredient?.name || 'Composed ingredient'}</b>

          {props.item.descriptions && (
            <>
              <br />
              <i>{props.item.descriptions.join(', ')}</i>
            </>
          )}
          {props.item.amount && (
            <>
              <br />
              <i>{props.item.amount}</i>
            </>
          )}
          {props.item.eco && (
            <>
              <br />
              <i>{props.item.eco} </i>✅
            </>
          )}
          {props.item.origin && (
            <>
              <br />
              <i>{props.item.origin}</i>
            </>
          )}
        </>
      }>
      <Typography.Text>
        {props.item.foundName} ({props.item.jaccard})
      </Typography.Text>
    </Tooltip>
  );
}
