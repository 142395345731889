import { Badge } from 'antd';
import { FC } from 'react';
import { ProductApproval } from '../../../model/product';
import { Status } from './types';

export interface IApproveBadgeProps {
  approved: ProductApproval | null;
  productId: number;
}

export const ApprovedBadge: FC<IApproveBadgeProps> = (props) => {
  const { approved, children } = props;
  const status =
    !approved || approved.ok === null ? Status.NotDefined : approved.ok ? Status.Approved : Status.Disapproved;

  return (
    <Badge.Ribbon
      text={
        <span>
          {status === Status.Approved ? 'Approved' : status === Status.Disapproved ? 'Not approved' : 'Not defined'}
        </span>
      }
      color={status === Status.Approved ? 'green' : status === Status.Disapproved ? 'yellow' : 'blue'}>
      {children}
    </Badge.Ribbon>
  );
};
