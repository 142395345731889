import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumb } from '../../components/Breadcrumb';
import { Affix, Button, Card, Checkbox, message, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useAdvancedProductSearch, useAdvancedProductSearchTotal } from '../../api/product';
import { ProductList } from '../../components/ProductList';
import { ArrayParam, JsonParam, useQueryParam, withDefault } from 'use-query-params';
import { PlusOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { ROUTER_PAGES } from '../../routes';
import { useAddToGroup, useSingleGroup } from '../../api/group';
import { OrStatement } from 'src/model/productQuery';
import { SearchBar } from 'src/components/SearchBar/SearchBar';
import { notNullGuard } from '../../utils/typescript';

/**
 * Page for finding which products which should be added to a group.
 */
const FindProductPage = () => {
  const [empty, setEmpty] = useState(true);
  const [add] = useAddToGroup();
  const { id: groupId } = useParams<{ id: string }>();

  const [checkedGtins, setCheckedGtins] = useQueryParam(
    'selectedGtins',
    withDefault(ArrayParam, [] as Array<string | null>)
  );
  const filteredCheckedGtins = useMemo(() => checkedGtins.filter(notNullGuard), [checkedGtins]);

  const [offset, setOffset] = useState(0);
  const limit = 25;
  const [filter, setFilter] = useQueryParam<OrStatement>('filter', JsonParam);

  const { data, fetchMore, loading } = useAdvancedProductSearch({
    variables: {
      filter,
      offset,
      limit,
    },
  });

  const { data: totalData } = useAdvancedProductSearchTotal({
    variables: {
      filter,
    },
  });

  const total = totalData?.response.total;
  const hasMore = data?.response.hasMore;

  useEffect(() => {
    fetchMore({ variables: { filter, offset, limit } }).catch((err) => console.error(err));
  }, [offset, limit, filter, fetchMore]);

  const { data: group } = useSingleGroup({
    variables: {
      id: Number.parseInt(groupId),
    },
  });

  const addToGroup = useCallback(() => {
    add({
      variables: {
        externalIds: filteredCheckedGtins,
        id: Number.parseInt(groupId),
      },
    })
      .then(() => {
        setCheckedGtins([]);
        return message.success(`added ${checkedGtins.length} gtins to group`);
      })
      .catch((e) => {
        return message.error(e.message);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [add, checkedGtins.length, filteredCheckedGtins, groupId, setCheckedGtins]);

  const actions = (
    <>
      <Button
        loading={loading}
        onClick={() => {
          if (data) {
            setCheckedGtins(data?.response.products.map((product) => product.productIdentifier.externalId));
          }
        }}
        icon={<UsergroupAddOutlined />}>
        Select all
      </Button>
      <Button onClick={() => setCheckedGtins([])} icon={<UsergroupAddOutlined />}>
        Unselect all
      </Button>
      <Button icon={<PlusOutlined />} block={true} onClick={addToGroup}>
        Add {checkedGtins.length} to group
      </Button>
    </>
  );

  const loadMore = useCallback(() => {
    const dataLength = data?.response.products.length;
    if (dataLength) setOffset(dataLength);
  }, [data?.response.products.length]);

  const content = useCallback(
    (p) => {
      return (
        <div
          style={{ padding: '30px' }}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setCheckedGtins([...checkedGtins, p.productIdentifier.externalId]);
              } else {
                setCheckedGtins([...checkedGtins].filter((v) => v !== p.productIdentifier.externalId));
              }
            }}
            value={checkedGtins.includes(p.productIdentifier.externalId)}
            checked={checkedGtins.includes(p.productIdentifier.externalId)}
          />
        </div>
      );
    },
    [checkedGtins, setCheckedGtins]
  );

  const handleSubmit = useCallback(
    (filter) => {
      setOffset(0);
      setFilter(filter);
    },
    [setFilter]
  );

  return (
    <>
      <Breadcrumb />
      <Link to={ROUTER_PAGES.editGroups.path.replace(':id', groupId?.toString())}>
        <Typography.Title underline level={1}>
          {group?.group.name} ({total || 'Counting...'})
        </Typography.Title>
      </Link>
      <Affix>
        <Card>
          <SearchBar
            extraActions={actions}
            defaultValues={filter}
            loading={loading}
            singleRow
            onSubmit={handleSubmit}
            onClear={(empty) => {
              setEmpty(empty);
            }}
          />
        </Card>
      </Affix>

      {data?.response.products && (
        <ProductList
          loading={loading}
          loadMore={loadMore}
          content={content}
          hasMore={hasMore}
          products={data.response.products}
        />
      )}
    </>
  );
};

export default FindProductPage;
