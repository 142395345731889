import { BuildOutlined, ForkOutlined, QuestionCircleOutlined, SearchOutlined, TagsOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ROUTER_PAGES } from '../routes';

const { Sider } = Layout;

interface MenuItem {
  path?: string;
  title: string;
  icon?: JSX.Element;
  subMenuItems?: MenuItem[];
}

const menuItems: MenuItem[] = [
  {
    path: ROUTER_PAGES.search.path,
    title: 'Search',
    icon: <SearchOutlined />,
  },
  {
    path: ROUTER_PAGES.tags.path,
    title: 'Tags',
    icon: <TagsOutlined />,
  },
  {
    title: 'Groups',
    icon: <BuildOutlined />,
    subMenuItems: [
      {
        path: ROUTER_PAGES.groups.path,
        title: 'Edit groups',
        icon: <BuildOutlined />,
      },
      {
        path: ROUTER_PAGES.groupHierachy.path,
        title: 'Edit hierarchy',
        icon: <ForkOutlined />,
      },
    ],
  },
  {
    path: ROUTER_PAGES.ingredientsEdit.path,
    title: 'Ingredients',
    icon: <QuestionCircleOutlined />,
  },
];

const SideMenu = withRouter(({ location }: RouteComponentProps) => {
  const renderMenuItems = (items: MenuItem[]) => {
    return items.map((item) => {
      if (item.subMenuItems && item.subMenuItems.length > 0) {
        return (
          <SubMenu key={item.title} title={item.title} icon={item.icon}>
            {renderMenuItems(item.subMenuItems)}
          </SubMenu>
        );
      } else {
        return (
          <Menu.Item key={item.path} icon={item.icon}>
            <Link to={item.path! /* FIXME: undefined */}>{item.title}</Link>
          </Menu.Item>
        );
      }
    });
  };

  return (
    <>
      <Sider
        theme="light"
        breakpoint="sm"
        hidden={[ROUTER_PAGES.signIn.path].includes(location.pathname) || location.pathname.startsWith('/public')}
        collapsible={true}>
        <Menu
          className="sidebar-menu"
          theme="light"
          defaultSelectedKeys={[ROUTER_PAGES.search.path]}
          mode="vertical"
          selectedKeys={[location.pathname]}>
          {renderMenuItems(menuItems)}
        </Menu>
      </Sider>
    </>
  );
});

export default SideMenu;
